const VisaIcon = () => {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V14C24 15.1046 23.1046 16 22 16H2C0.89543 16 0 15.1046 0 14V2Z"
        fill="#D8E3F3"
      />
      <path
        d="M20.6553 5.01752H19.398C19.0178 5.01752 18.7254 5.13448 18.55 5.51459L16.1523 10.9824H17.8482C17.8482 10.9824 18.1406 10.2514 18.1991 10.076C18.3746 10.076 20.0412 10.076 20.2752 10.076C20.3336 10.2807 20.4798 10.9532 20.4798 10.9532H22.0003L20.6553 5.01752ZM18.667 8.84793C18.8132 8.49705 19.3102 7.18126 19.3102 7.18126C19.3102 7.2105 19.4564 6.83038 19.5149 6.6257L19.6319 7.15202C19.6319 7.15202 19.9535 8.58477 20.012 8.87717H18.667V8.84793Z"
        fill="#3362AB"
      />
      <path
        d="M16.2692 9.02338C16.2692 10.2514 15.1581 11.0702 13.433 11.0702C12.702 11.0702 12.0002 10.924 11.6201 10.7485L11.854 9.40349L12.0587 9.49121C12.585 9.72513 12.9359 9.81285 13.5792 9.81285C14.047 9.81285 14.5441 9.63741 14.5441 9.22806C14.5441 8.9649 14.3394 8.78946 13.6961 8.49706C13.0821 8.20466 12.2634 7.73683 12.2634 6.88887C12.2634 5.71928 13.4037 4.92981 15.0119 4.92981C15.626 4.92981 16.1523 5.04677 16.4739 5.19297L16.24 6.47952L16.123 6.36256C15.8306 6.2456 15.4505 6.12864 14.895 6.12864C14.2809 6.15788 13.9885 6.42104 13.9885 6.65496C13.9885 6.91811 14.3394 7.12279 14.895 7.38595C15.8306 7.82455 16.2692 8.32162 16.2692 9.02338Z"
        fill="#3362AB"
      />
      <path
        d="M2 5.07604L2.02924 4.95908H4.54386C4.89474 4.95908 5.15789 5.07603 5.24561 5.45615L5.80117 8.08773C5.24561 6.68422 3.95906 5.54387 2 5.07604Z"
        fill="#F9B50B"
      />
      <path
        d="M9.33939 5.01752L6.79553 10.9532H5.07039L3.6084 5.98243C4.66103 6.65495 5.53822 7.70758 5.85986 8.43857L6.0353 9.05261L7.61425 4.98828H9.33939V5.01752Z"
        fill="#3362AB"
      />
      <path
        d="M10.0117 4.98828H11.6199L10.5965 10.9532H8.98828L10.0117 4.98828Z"
        fill="#3362AB"
      />
    </svg>
  );
};

export default VisaIcon;
