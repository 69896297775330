import { Alert } from 'antd';
import styled from 'styled-components';

const AlertContainer = styled(Alert)`
  margin-bottom: 20px !important;
  z-index: 0 !important;
  .ant-alert-content {
    flex: inherit;
  }
  .ant-alert-action {
    margin-top: 2px;
    & > button {
      border: none;
      background: none;
      padding: 0;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 700;
      color: ${(props) => props.theme.styles.colorBlack};
      font-family: ${(props) => props.theme.styles.fontProximaNova};
      &:disabled {
        cursor: not-allowed;
        color: ${(props) => props.theme.styles.colorGray};
      }
    }
  }
`;

export default AlertContainer;
