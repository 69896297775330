const TrashSolid = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 22 24"
      fill="#999999"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5625 0.5625C7.94141 0.183594 8.47656 0 9 0H13C13.5234 0 14.0586 0.183594 14.4375 0.5625C14.8164 0.941406 15 1.47656 15 2V3H22V5H20.9062L19 23.0938L18.9062 24H3.09375L3 23.0938L1.09375 5H0V3H7V2C7 1.47656 7.18359 0.941406 7.5625 0.5625ZM13 2H9V3H13V2ZM3.125 5H18.875L17.0938 22H4.90625L3.125 5ZM7 19V8H9V19H7ZM10 8V19H12V8H10ZM13 19V8H15V19H13Z"
      />
    </svg>
  );
};

export default TrashSolid;
