import { TextContainer } from './Typography.style';

const Text = ({ className, children, ...restProps }) => {
  return (
    <TextContainer className={`text-container ${className}`} {...restProps}>
      {children}
    </TextContainer>
  );
};

export default Text;
