import styled from 'styled-components';
import { Checkbox, Input, Select } from 'antd';

const { TextArea } = Input;

export const InputContainer = styled(Input)`
  &.ant-input-affix-wrapper {
    position: relative;
    height: 50px;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: ${(props) => props.theme.styles.colorWhite};
    background-image: none;
    border: 1.5px solid ${(props) => props.theme.styles.inputGray};
    border-radius: 3px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -ms-inline-flexbox;
    display: inline-flex;
    &:hover {
      border-color: ${(props) => props.theme.styles.inputActiveGray};
    }

    &:focus,
    &.ant-input-affix-wrapper-focused {
      border-right-width: 1px !important;
      outline: 0;
      -webkit-box-shadow: 0 0 2px 1px
        ${(props) => props.theme.styles.inputActiveGray};
      box-shadow: 0 0 1px 1px ${(props) => props.theme.styles.inputActiveGray};
    }

    &.ant-input-affix-wrapper-disabled {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      cursor: not-allowed;
      opacity: 1;
    }
  }

  .ant-input-prefix {
    margin-right: 4px;
    color: ${(props) => props.theme.styles.colorGrayLight};
    opacity: 0.5;
    font-family: ${(props) => props.theme.styles.fontProximaNova};
    font-weight: 400;
    font-size: ${(props) => props.theme.styles.fontSizeSmall};
    line-height: 20px;
  }

  &.ant-input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    height: 50px;
    font-family: ${(props) => props.theme.styles.fontProximaNova};
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 1.5715;
    background-color: ${(props) => props.theme.styles.colorWhite};
    background-image: none;
    border: 1.5px solid ${(props) => props.theme.styles.inputGray};
    border-radius: 3px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      border-color: ${(props) => props.theme.styles.inputActiveGray};
    }

    &:focus,
    .ant-input-focused {
      border-color: transparent !important;
      border-right-width: 1px !important;
      outline: 0;
      -webkit-box-shadow: 0 0 2px 1px
        ${(props) => props.theme.styles.inputActiveGray};
      box-shadow: 0 0 2px 1px ${(props) => props.theme.styles.inputActiveGray};
    }

    &.ant-input-disabled {
      color: ${(props) => props.theme.styles.colorGrayLight};
      opacity: 0.6;
      background-color: #f4f4f4;
      cursor: not-allowed;
      &:hover {
        border-color: ${(props) => props.theme.styles.colorGrayDeep};
      }
    }
    &::placeholder {
      color: ${(props) => props.theme.styles.colorGrayLight};
      opacity: 0.5;
      font-family: ${(props) => props.theme.styles.fontProximaNova};
      font-weight: 400;
      font-size: ${(props) => props.theme.styles.fontSizeSmall} !important;
      line-height: 20px;
    }
  }
`;

export const SelectContainer = styled(Select)`
  width: 100%;
  font-family: outfit;
  .ant-select-selector {
    background: #ffffff;
    border: 1.5px solid #c9c9c9;
    border-radius: 3px;
    padding-block: 11px;

    padding-right: ${(props) =>
      props.selectedItems?.length ? '15px' : '75px'};
  }
  .ant-select-dropdown {
    border: 1px solid red !important;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #a6b7d4;
    height: 32px;
    background: #ebf2fa;
    border-radius: 6px;
  }
  .ant-select-selection-placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    color: #262626;
    opacity: 0.6;
  }
`;

export const CheckboxContainer = styled(Checkbox)`
  margin-top: 5px;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2dccd3;
    border-color: #2dccd3;
  }

  &:hover {
    border-color: ${(props) => props.theme.styles.inputActiveGray};
  }

  &:focus {
    outline: #2dccd3;
    border-color: #2dccd3;
  }

  a {
    color: #2dccd3;
  }

  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #2dccd3;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #2dccd3;
  }

  .ant-checkbox-checked::after {
    border-color: #2dccd3;
  }

  /* border-color: ${(props) => props.theme.styles.colorGrayDeep} !important; */
`;

export const InputPasswordContainer = styled(Input.Password)`
  &.ant-input-affix-wrapper {
    position: relative;
    height: 50px;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: ${(props) => props.theme.styles.colorWhite};
    background-image: none;
    border: 1.5px solid ${(props) => props.theme.styles.inputGray};
    border-radius: 3px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -ms-inline-flexbox;
    display: inline-flex;
    &:hover {
      border-color: ${(props) => props.theme.styles.inputActiveGray};
    }

    &:focus,
    &.ant-input-affix-wrapper-focused {
      border-right-width: 1px !important;
      outline: 0;
      -webkit-box-shadow: 0 0 2px 1px
        ${(props) => props.theme.styles.inputActiveGray};
      box-shadow: 0 0 2px 1px ${(props) => props.theme.styles.inputActiveGray};
    }

    &.ant-input-affix-wrapper-disabled {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      cursor: not-allowed;
      opacity: 1;
    }
  }

  .ant-input-prefix {
    margin-right: 12.25px;
    color: ${(props) => props.theme.styles.colorGrayLight};
    opacity: 0.5;
    font-family: ${(props) => props.theme.styles.fontProximaNova};
    font-weight: 400;
    font-size: ${(props) => props.theme.styles.fontSizeSmall};
    line-height: 20px;
  }

  .ant-input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    font-family: ${(props) => props.theme.styles.fontProximaNova};
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 1.5715;
    background-color: ${(props) => props.theme.styles.colorWhite};
    background-image: none;
    border: 1.5px solid ${(props) => props.theme.styles.inputGray};
    border-radius: 3px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      border-color: ${(props) => props.theme.styles.inputActiveGray};
    }

    &:focus,
    .ant-input-focused {
      border-color: transparent !important;
      border-right-width: 1px !important;
      outline: 0;
      -webkit-box-shadow: 0 0 2px 1px
        ${(props) => props.theme.styles.inputActiveGray};
      box-shadow: 0 0 2px 1px ${(props) => props.theme.styles.inputActiveGray};
    }

    &.ant-input-disabled {
      color: ${(props) => props.theme.styles.colorGray};
      background-color: ${(props) => props.theme.styles.colorGray};
      cursor: not-allowed;
      opacity: 1;
      &:hover {
        border-color: ${(props) => props.theme.styles.colorGrayDeep};
      }
    }
    &::placeholder {
      color: ${(props) => props.theme.styles.colorGrayLight};
      opacity: 0.5;
      font-family: ${(props) => props.theme.styles.fontProximaNova};
      font-weight: 400;
      font-size: ${(props) => props.theme.styles.fontSizeSmall} !important;
      line-height: 20px;
    }
  }
`;

export const InputTextareaContainer = styled(TextArea)`
  &.ant-input-textarea {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 120px !important;

    & > .ant-input {
      padding-top: 12px !important;
      min-height: 120px !important;
      overflow-y: hidden !important;
      border: 1.5px solid ${(props) => props.theme.styles.inputGray};
      border-radius: 3px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      font-family: ${(props) => props.theme.styles.fontProximaNova};
      list-style: none;
      -webkit-font-feature-settings: 'tnum';
      font-feature-settings: 'tnum', 'tnum';
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      line-height: 1.5715;
      background-color: ${(props) => props.theme.styles.colorWhite};
      background-image: none;
      white-space: pre-line;
      &:hover {
        border-color: ${(props) =>
          props.theme.styles.inputActiveGray} !important;
      }
      &:focus {
        border-color: transparent !important;
        border-right-width: 1px !important;
        outline: 0;
        -webkit-box-shadow: 0 0 2px 1px
          ${(props) => props.theme.styles.inputActiveGray};
        box-shadow: 0 0 2px 1px ${(props) => props.theme.styles.inputActiveGray};
      }
      &::placeholder {
        color: ${(props) => props.theme.styles.colorGrayLight};
        opacity: 0.5;
        font-family: ${(props) => props.theme.styles.fontProximaNova};
        font-weight: 400;
        font-size: ${(props) => props.theme.styles.fontSizeSmall};
        line-height: 20px;
      }
    }
  }

  /* border-color: ${(props) => props.theme.styles.colorGrayDeep} !important; */
`;
