const MastercardIcon = () => {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V14C24 15.1046 23.1046 16 22 16H2C0.89543 16 0 15.1046 0 14V2Z"
        fill="#FDEACE"
      />
      <path
        d="M14.5044 3.26371L9.33984 3.28146L9.49634 12.7364L14.6609 12.7186L14.5044 3.26371Z"
        fill="#FF5F00"
      />
      <path
        d="M9.6798 8.02799C9.64784 6.10494 10.5093 4.39623 11.8586 3.28788C10.8401 2.48865 9.56328 2.00805 8.17913 2.01279C4.90002 2.02403 2.29181 4.72539 2.34711 8.05311C2.40241 11.3808 5.1001 14.064 8.37922 14.0528C9.76336 14.048 11.0241 13.5588 12.0159 12.7526C10.6302 11.6704 9.71176 9.95105 9.6798 8.02799Z"
        fill="#EB001B"
      />
      <path
        d="M21.6531 7.94696C21.7084 11.2747 19.1002 13.976 15.8211 13.9873C14.4369 13.992 13.1601 13.5114 12.1416 12.7122C13.5074 11.6038 14.3524 9.89513 14.3204 7.97207C14.2885 6.04902 13.3703 4.3464 11.9843 3.24742C12.9761 2.44131 14.2369 1.95202 15.621 1.94728C18.9001 1.93605 21.5981 4.63596 21.6531 7.94696Z"
        fill="#F79E1B"
      />
    </svg>
  );
};

export default MastercardIcon;
