const CheckSolid = ({ className }) => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.373 0.310547L4.8125 7.87109L1.62695 4.68555L0.998047 5.31445L4.49805 8.81445L4.8125 9.11523L5.12695 8.81445L13.002 0.939453L12.373 0.310547Z"
        fill="black"
      />
    </svg>
  );
};

export default CheckSolid;
