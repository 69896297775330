import styled from 'styled-components';
import { Form } from 'antd';

const PaymentContainer = styled.div`
  &.editPayment {
    & > div > .ant-row {
      margin-bottom: 10px !important;
    }
  }
`;
const PaymentLabel = styled.label`
  font-family: ${(props) => props.theme.styles.fontNunitoSans};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const StripeContent = styled(Form)`
  margin: 10px 0 20px 0;

  .disabled {
    .ant-form-item-control {
      & > .ant-form-item-control-input {
        & > .ant-form-item-control-input-content {
          cursor: not-allowed;
        }
      }
    }
  }
`;

export { PaymentContainer, PaymentLabel, StripeContent };
