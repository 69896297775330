import styled from 'styled-components';
import { Input } from 'antd';

import Modal from 'src/components/common/Modal/Modal';

const ShareTeamModalContainer = styled(Modal)`
  .ant-modal-footer {
    justify-content: start !important;
    & > a {
      font-family: ${(props) => props.theme.styles.fontNunitoSans};
      font-size: ${(props) => props.theme.styles.fontSizeSmall};
      font-weight: bold;
      color: ${(props) => props.theme.styles.colorPink} !important;
      text-decoration: underline !important;
    }
  }
`;

const SecuritySectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 50px 0;
`;

const SecurityText = styled.span`
  font-family: ${(props) => props.theme.styles.fontNunitoSans};
  font-weight: 400;
  font-size: ${(props) => props.theme.styles.fontSizeSmall};
  color: ${(props) => props.theme.styles.colorBlack};
`;

const CopyUrlTitle = styled.div`
  font-family: ${(props) => props.theme.styles.fontNunitoSans};
  font-weight: 700;
  font-size: ${(props) => props.theme.styles.fontSizeSmall};
  color: ${(props) => props.theme.styles.colorBlack};
  margin-bottom: 20px;
`;

const CopyUrlInput = styled(Input)`
  width: calc(100% - 200px) !important;
  @media (max-width: 768px) {
    width: calc(100% - 120px) !important;
  }
  @media (max-width: 480px) {
    margin-bottom: -1px !important;
    width: 100% !important;
  }
  &.ant-input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    height: 50px;
    font-family: ${(props) => props.theme.styles.fontNunitoSans};
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 1.5715;
    background-color: ${(props) => props.theme.styles.colorWhite};
    background-image: none;
    border: 1px solid #c9c9c9;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    &:hover {
      border-color: ${(props) => props.theme.styles.colorPink};
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }

    &:focus,
    .ant-input-focused {
      border-right-width: 1px !important;
      outline: 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      border-color: transparent;
      -webkit-box-shadow: 0 0 2px 1px ${(props) => props.theme.styles.colorPink};
      box-shadow: 0 0 2px 1px ${(props) => props.theme.styles.colorPink};
    }

    &.ant-input-disabled {
      color: #d8d8d8;
      background-color: #d8d8d8;
      cursor: not-allowed;
      opacity: 1;
      &:hover {
        border-color: ${(props) => props.theme.styles.colorGrayDeep};
      }
    }
  }
  & + button {
    width: 200px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    & > svg {
      fill: ${(props) => props.theme.styles.colorBlack} !important;
      cursor: pointer;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    & > span {
      margin-top: 5px !important;
      margin-left: 20px !important;
    }
    &:hover {
      & > svg {
        fill: ${(props) => props.theme.styles.colorPink} !important;
        cursor: pointer;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
    @media (max-width: 768px) {
      width: 120px;
    }
    @media (max-width: 480px) {
      width: 100%;
    }
  }
`;

const CopyUrlInputGroup = styled(Input.Group)`
  display: flex !important;
  .copy-text {
    @media (max-width: 768px) {
      display: none;
    }
  }
  @media (max-width: 480px) {
    display: inline-block !important;
  }
`;

export {
  ShareTeamModalContainer,
  SecuritySectionWrapper,
  SecurityText,
  CopyUrlTitle,
  CopyUrlInput,
  CopyUrlInputGroup,
};
