import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import BillingForm from 'src/form/BillingForm/BillingForm';

const Billing = () => {
  const user = useSelector((store) => store.userState.currentUser.user);

  if (user?.membership) {
    return <Navigate to="/" />;
  }
  return <BillingForm />;
};

export default Billing;
