import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';

const { Title, Paragraph, Text } = Typography;

const colors = {
  black: (props) => props.theme.styles.colorBlack,
  lightblack: (props) => props.theme.styles.colorLightBlack,
  white: (props) => props.theme.styles.colorWhite,
  blue: (props) => props.theme.styles.colorBlue,
  lightBlue: (props) => props.theme.styles.colorLightBlue,
  yellow: (props) => props.theme.styles.colorYellow,
  pink: (props) => props.theme.styles.colorPink,
  green: (props) => props.theme.styles.colorGreen,
};

export const TypographyContainer = styled(Typography)`
  &.ant-typography {
    font-family: ${(props) => props.theme.styles.fontProximaNova};
    color: ${(props) =>
      props.color ? colors[props.color] : 'rgba(0, 0, 0, 0.85)'} !important;
    overflow-wrap: break-word;
  }
`;

export const TitleContainer = styled(Title)`
  font-family: ${(props) => props.theme.styles.fontOutfit};
  font-size: ${(props) => {
    if (props.level === 1) return (prop) => prop.theme.styles.fontSizeBiggest;
    if (props.level === 2) return (prop) => prop.theme.styles.fontSizeBigger;
    if (props.level === 3) return (prop) => prop.theme.styles.fontSizeBig;
    if (props.level === 4) return (prop) => prop.theme.styles.fontSizeMedium;
    return (prop) => prop.theme.styles.fontSizeBiggest;
  }} !important;
  color: ${(props) =>
    props.color ? colors[props.color] : 'rgba(0, 0, 0, 0.85)'} !important;
`;

export const ParagraphContainer = styled(Paragraph)`
  font-family: ${(props) => props.theme.styles.fontProximaNova};
  font-size: ${(props) => props.theme.styles.fontSizeSmallest};
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${(props) =>
    props.color ? colors[props.color] : '#425466'} !important;
`;

export const TextContainer = styled(Text)`
  font-family: ${(props) => props.theme.styles.fontInter};
  // font-size: ${(props) => props.theme.styles.fontSizeSmall};
  font-size: ${(props) =>
    props.fontSize
      ? props.fontSize
      : props.theme.styles.fontSizeSmall} !important;
  font-style: normal;
  /* font-weight: 600; */
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : 600} !important;
  color: ${(props) =>
    props.color ? colors[props.color] : '#262626'} !important;
`;

export const DeleteTextContainer = styled(Text)`
  font-family: ${(props) => props.theme.styles.fontInter};
  cursor: pointer;
  font-size: ${(props) =>
    props.fontSize
      ? props.fontSize
      : props.theme.styles.fontSizeSmall} !important;
  font-style: normal;
  /* font-weight: 600; */
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : 600} !important;
  color: red !important;
  margin-right:auto;
`;



export const LinkContainer = styled(Link)`
  font-family: ${(props) => props.theme.styles.fontProximaNova};
  font-size: ${(props) => props.theme.styles.fontSizeSmall};
  font-weight: 400;
  font-style: normal;
  color: ${(props) => props.theme.styles.colorLightBlue} !important;
  text-decoration: underline !important;
  &[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }
`;
