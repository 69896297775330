import { ModalContainer } from './Modal.styles';

const Modal = (props) => {
  const {
    title,
    footer,
    isModalVisible,
    handleOk,
    handleCancel,
    children,
    className,
    closeIcon,
  } = props;

  return (
    <ModalContainer
      className={className}
      title={title}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footer}
      closeIcon={closeIcon}
    >
      {children}
    </ModalContainer>
  );
};

export default Modal;
