const Discover = () => {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V14C24 15.1046 23.1046 16 22 16H2C0.89543 16 0 15.1046 0 14V2Z"
        fill="#FCE0CF"
      />
      <path
        d="M14.2901 7.99112C14.292 7.56126 14.1308 7.13078 13.8064 6.80638C13.4814 6.48131 13.0498 6.32027 12.6191 6.32286C12.617 6.32286 12.615 6.32272 12.6129 6.32272C11.6775 6.32272 10.9355 7.06449 10.9355 8.00013C10.9355 8.92654 11.6866 9.67741 12.6129 9.67741C13.5485 9.67741 14.2904 8.93551 14.2904 8.00013C14.2904 7.99706 14.2901 7.99416 14.2901 7.99112Z"
        fill="#F26E21"
      />
      <path
        d="M10.6237 8.70967C10.373 8.96065 10.1416 9.0587 9.89234 9.06401C9.28516 9.05856 8.83875 8.6089 8.83875 7.96782C8.83875 7.64511 8.96094 7.38062 9.1545 7.15467C9.33602 6.98234 9.56986 6.871 9.83877 6.871C10.129 6.871 10.3549 6.96776 10.6128 7.22582V6.48392C10.355 6.35496 10.129 6.29041 9.83877 6.29041C9.42871 6.31971 9.04208 6.48539 8.74552 6.74556C8.66005 6.82246 8.57814 6.90736 8.50869 7.00452C8.29206 7.28529 8.16113 7.62909 8.16113 8.0002C8.16113 8.93553 8.9033 9.64518 9.83872 9.64518C9.84327 9.64518 9.84778 9.64478 9.85219 9.64473C9.85848 9.64482 9.86468 9.64518 9.87093 9.64518C10.129 9.64518 10.3549 9.58081 10.6452 9.45175L10.6237 8.70967C10.6128 8.72056 10.6345 8.73068 10.6237 8.74099V8.70967Z"
        fill="#2B2B2B"
      />
      <path
        d="M16.4077 6.75235L15.6772 8.54842L14.8384 6.41925H14.1611L15.4836 9.67731H15.8385L17.2255 6.41925H16.5482L16.4077 6.75235Z"
        fill="#2B2B2B"
      />
      <path
        d="M17.4844 7.83864V9.58069H19.2264H19.2585V9.06438H18.7102H18.1295V8.48387V8.19364H19.2264V7.64508H18.1295V6.96773H19.2585V6.41925H17.4844V7.83864Z"
        fill="#2B2B2B"
      />
      <path
        d="M2.90339 6.41925H2V9.58069H2.90334C3.38714 9.58069 3.74192 9.45154 4.03224 9.22573C4.38702 8.93541 4.61292 8.48392 4.61292 8.00013C4.61296 7.06439 3.93543 6.41925 2.90339 6.41925ZM3.64525 8.80649C3.45169 8.96771 3.19362 9.06438 2.8065 9.06438H2.61294V6.96768H2.7742C3.16132 6.96768 3.41939 7.03219 3.61299 7.22566C3.83876 7.41909 3.96786 7.70958 3.96786 7.99999C4.00012 8.29031 3.87111 8.61275 3.64525 8.80649Z"
        fill="#2B2B2B"
      />
      <path
        d="M5.51621 6.41901H4.90332V9.58036H5.51621V6.41901Z"
        fill="#2B2B2B"
      />
      <path
        d="M7.06484 7.64511C6.6778 7.5161 6.58091 7.41925 6.58091 7.25803C6.58091 7.06447 6.77447 6.90321 7.03254 6.90321C7.2261 6.90321 7.38736 6.96776 7.54867 7.16132L7.87133 6.74199C7.61318 6.51608 7.29074 6.38721 6.93587 6.38721C6.38735 6.38721 5.93572 6.77433 5.93572 7.29028C5.93572 7.742 6.12946 7.93552 6.71001 8.16133C6.96808 8.25796 7.06488 8.29034 7.12952 8.35485C7.25831 8.41926 7.32295 8.54841 7.32295 8.67729C7.32295 8.9354 7.12952 9.129 6.83902 9.129C6.54844 9.129 6.29059 8.96774 6.16154 8.70963L5.77441 9.09675C6.06487 9.51608 6.41969 9.7096 6.87132 9.7096C7.51642 9.7096 7.96782 9.29027 7.96782 8.67729C7.96791 8.12903 7.77453 7.90322 7.06484 7.64511Z"
        fill="#2B2B2B"
      />
      <path
        d="M21.0004 8.25792C21.4841 8.1613 21.7423 7.83859 21.7423 7.35485C21.7423 6.7743 21.3228 6.41925 20.6133 6.41925H19.6777V9.58069H20.2906V8.32248H20.3875L21.2583 9.58069H22.0003L21.0004 8.25792ZM20.4841 7.8708H20.2907V6.90318H20.4841C20.8712 6.90318 21.0971 7.0644 21.0971 7.38719C21.0971 7.70963 20.8713 7.8708 20.4841 7.8708Z"
        fill="#2B2B2B"
      />
    </svg>
  );
};

export default Discover;
