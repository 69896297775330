const JCB = () => {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V14C24 15.1046 23.1046 16 22 16H2C0.89543 16 0 15.1046 0 14V2Z"
        fill="#CCEAFF"
      />
      <path
        d="M20 11.6745C20 13.0488 18.8861 14.1632 17.5114 14.1632H4V4.32586C4 2.95118 5.11392 1.83676 6.48887 1.83676H19.9999L19.9999 11.6745L20 11.6745Z"
        fill="white"
      />
      <path
        d="M15.5953 8.21584C15.9812 8.22423 16.3693 8.1988 16.7537 8.22905C17.1429 8.30167 17.2368 8.89096 16.8909 9.08396C16.655 9.2111 16.3747 9.13132 16.1188 9.15374H15.5953V8.21584ZM16.9768 7.15427C17.0626 7.4569 16.7708 7.72863 16.4793 7.68695H15.5953C15.6015 7.40155 15.5832 7.09178 15.6043 6.82141C15.9584 6.83138 16.3158 6.80107 16.6679 6.83726C16.8192 6.87527 16.9458 6.99964 16.9768 7.15427ZM19.1044 2.66622C19.1209 3.24418 19.1068 3.8527 19.1115 4.44238C19.1104 6.83984 19.1138 9.23723 19.1096 11.6348C19.0941 12.5333 18.2978 13.3141 17.4055 13.332C16.5124 13.3356 15.6191 13.3325 14.7258 13.3335V9.70908C15.6991 9.70406 16.6729 9.71929 17.6457 9.70149C18.097 9.67315 18.5914 9.37537 18.6124 8.87871C18.6655 8.37991 18.1953 8.0349 17.7488 7.98041C17.5771 7.97595 17.5822 7.93038 17.7488 7.9105C18.1746 7.81846 18.509 7.37771 18.3837 6.9363C18.277 6.47204 17.7638 6.29236 17.337 6.29325C16.4668 6.28734 15.5963 6.29242 14.726 6.2907C14.7317 5.61406 14.7143 4.9367 14.7354 4.26055C14.8043 3.37826 15.6207 2.65067 16.5004 2.66645C17.3685 2.66632 18.2365 2.66632 19.1044 2.66629V2.66622Z"
        fill="url(#paint0_linear_351_26995)"
      />
      <path
        d="M4.89131 4.35194C4.91354 3.45486 5.71323 2.68053 6.60437 2.66742C7.49421 2.66468 8.38419 2.66702 9.27404 2.66623C9.2716 5.66767 9.27886 8.66945 9.27038 11.6707C9.2361 12.5569 8.44512 13.3165 7.5637 13.3322C6.67216 13.3354 5.78053 13.3326 4.88897 13.3335V9.58687C5.75497 9.79146 6.66312 9.87854 7.54659 9.74285C8.07469 9.65787 8.65249 9.39853 8.83128 8.85065C8.96285 8.382 8.88878 7.88878 8.90836 7.40777V6.29072H7.37942C7.37255 7.02951 7.39349 7.76959 7.36836 8.5075C7.32714 8.96106 6.87797 9.24923 6.45021 9.23384C5.9197 9.23945 4.86843 8.84937 4.86843 8.84937C4.86578 7.46517 4.88382 5.73168 4.89131 4.35207V4.35194Z"
        fill="url(#paint1_linear_351_26995)"
      />
      <path
        d="M9.84454 6.7413C9.76406 6.75837 9.82836 6.46716 9.80779 6.35669C9.81327 5.65822 9.79633 4.95899 9.81713 4.26102C9.88589 3.375 10.7085 2.64559 11.5918 2.66633H14.1931C14.1906 5.66777 14.1979 8.66954 14.1894 11.6708C14.1551 12.557 13.3641 13.3165 12.4826 13.3323C11.591 13.3356 10.6994 13.3328 9.80775 13.3336V9.22868C10.4167 9.72831 11.2443 9.80608 12.003 9.80744C12.5749 9.80724 13.1434 9.71906 13.6989 9.58716V8.83513C13.0729 9.14708 12.3372 9.34522 11.6433 9.166C11.1593 9.04553 10.808 8.57776 10.8158 8.07827C10.7597 7.55882 11.0642 7.01042 11.5747 6.85599C12.2084 6.65758 12.8992 6.80933 13.4932 7.06729C13.6205 7.13393 13.7497 7.21659 13.6988 7.00384V6.41274C12.7052 6.17635 11.6479 6.08936 10.6497 6.34652C10.3607 6.42803 10.0792 6.55164 9.84454 6.74136V6.7413Z"
        fill="url(#paint2_linear_351_26995)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_351_26995"
          x1="14.6959"
          y1="7.7718"
          x2="19.0839"
          y2="7.7718"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007B40" />
          <stop offset="1" stopColor="#55B330" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_351_26995"
          x1="4.79567"
          y1="8.10203"
          x2="9.20214"
          y2="8.10203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D2970" />
          <stop offset="1" stopColor="#006DBA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_351_26995"
          x1="9.80132"
          y1="7.87524"
          x2="14.1922"
          y2="7.87524"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E2B2F" />
          <stop offset="1" stopColor="#E30138" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default JCB;
