const RefreshIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.5095 11.1376C19.5855 11.6846 20.0906 12.0665 20.6376 11.9904C21.1847 11.9144 21.5665 11.4093 21.4905 10.8623L19.5095 11.1376ZM4.0769 8.6154C3.86451 9.12521 4.10562 9.71067 4.61544 9.92306C5.12525 10.1354 5.71071 9.89433 5.9231 9.38452L4.0769 8.6154ZM5.5 4.99996C5.5 4.44767 5.05228 3.99996 4.5 3.99996C3.94772 3.99996 3.5 4.44767 3.5 4.99996H5.5ZM4.5 8.99996H3.5C3.5 9.55224 3.94772 9.99996 4.5 9.99996V8.99996ZM8.5 9.99996C9.05228 9.99996 9.5 9.55224 9.5 8.99996C9.5 8.44767 9.05228 7.99996 8.5 7.99996V9.99996ZM21.4905 10.8623C20.9257 6.79825 17.711 3.61481 13.6416 3.08973L13.3857 5.07329C16.5607 5.48297 19.0689 7.96674 19.5095 11.1376L21.4905 10.8623ZM13.6416 3.08973C9.57226 2.56465 5.65481 4.82781 4.0769 8.6154L5.9231 9.38452C7.15421 6.42937 10.2107 4.66361 13.3857 5.07329L13.6416 3.08973ZM3.5 4.99996V8.99996H5.5V4.99996H3.5ZM4.5 9.99996H8.5V7.99996H4.5V9.99996Z" />
      <path d="M5.49048 12.8624C5.41446 12.3153 4.90938 11.9335 4.36235 12.0095C3.81532 12.0855 3.4335 12.5906 3.50952 13.1376L5.49048 12.8624ZM20.9231 15.3846C21.1355 14.8747 20.8944 14.2893 20.3846 14.0769C19.8747 13.8645 19.2893 14.1056 19.0769 14.6154L20.9231 15.3846ZM19.5 19C19.5 19.5523 19.9477 20 20.5 20C21.0523 20 21.5 19.5523 21.5 19H19.5ZM20.5 15H21.5C21.5 14.4477 21.0523 14 20.5 14V15ZM16.5 14C15.9477 14 15.5 14.4477 15.5 15C15.5 15.5523 15.9477 16 16.5 16V14ZM3.50952 13.1376C4.07431 17.2017 7.28897 20.3851 11.3584 20.9102L11.6143 18.9267C8.43929 18.517 5.93114 16.0332 5.49048 12.8624L3.50952 13.1376ZM11.3584 20.9102C15.4277 21.4353 19.3452 19.1721 20.9231 15.3846L19.0769 14.6154C17.8458 17.5706 14.7893 19.3363 11.6143 18.9267L11.3584 20.9102ZM21.5 19V15H19.5V19H21.5ZM20.5 14H16.5V16H20.5V14Z" />
    </svg>
  );
};

export default RefreshIcon;
