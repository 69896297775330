import types from './types';

export const addActivity = (payload) => (dispatch) =>
  dispatch({
    type: types.ADD_ACTIVITY,
    payload,
  });

export const addSuccessMessage = (payload) => (dispatch) =>
  dispatch({
    type: types.SUCCESS_MESSAGE,
    payload,
  });

export const addErrorMessage = (payload) => (dispatch) =>
  dispatch({
    type: types.ERROR_MESSAGE,
    payload,
  });

export default {
  addActivity,
  addSuccessMessage,
  addErrorMessage,
};
