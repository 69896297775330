const CodeCopy = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="7.8335"
        y="7.33325"
        width="11"
        height="11"
        rx="2"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1665 7.33341V5.50008C15.1665 4.48756 14.3457 3.66675 13.3332 3.66675H5.99984C4.98732 3.66675 4.1665 4.48756 4.1665 5.50008V12.8334C4.1665 13.8459 4.98732 14.6667 5.99984 14.6667H7.83317"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CodeCopy;
