const Linkedin = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1187_14499)">
        <path
          d="M0.333313 1.31115C0.333313 0.786068 0.772902 0.359863 1.31479 0.359863H12.6318C13.1739 0.359863 13.6133 0.786068 13.6133 1.31115V12.6887C13.6133 13.214 13.1739 13.6399 12.6318 13.6399H1.31479C0.772954 13.6399 0.333313 13.214 0.333313 12.6889V1.31099V1.31115Z"
          fill="#006699"
        />
        <path
          d="M4.36901 11.4736V5.49486H2.38178V11.4736H4.36922H4.36901ZM3.37581 4.67871C4.06866 4.67871 4.5 4.21962 4.5 3.64588C4.48703 3.05907 4.06866 2.61279 3.38899 2.61279C2.70886 2.61279 2.26465 3.05907 2.26465 3.64583C2.26465 4.21957 2.69583 4.67866 3.36279 4.67866H3.37566L3.37581 4.67871ZM5.46897 11.4736H7.45605V8.13515C7.45605 7.9567 7.46902 7.77778 7.52152 7.65032C7.66511 7.29316 7.99208 6.92345 8.54117 6.92345C9.26006 6.92345 9.54781 7.47167 9.54781 8.27547V11.4736H11.5348V8.04556C11.5348 6.20924 10.5546 5.3547 9.24719 5.3547C8.1753 5.3547 7.70453 5.9538 7.44298 6.36185H7.45621V5.49507H5.46908C5.49502 6.05594 5.46892 11.4738 5.46892 11.4738L5.46897 11.4736Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1187_14499">
          <rect
            width="13.3333"
            height="13.3333"
            fill="white"
            transform="translate(0.333313 0.333252)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Linkedin;
