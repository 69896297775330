import types from './types';

export const addInvitedMember = (payload) => (dispatch) => {
  return dispatch({
    type: types.ADD_INVITED_MEMBERS,
    payload,
  });
};

export const removeInvitedMember = (payload) => (dispatch) => {
  return dispatch({
    type: types.REMOVE_INVITED_MEMBER,
    payload,
  });
};

export const addSingleTeamData = (payload) => (dispatch) => {
  return dispatch({
    type: types.ADD_SINGLE_TEAM_DATA,
    payload,
  });
};

export default {
  addInvitedMember,
  removeInvitedMember,
  addSingleTeamData,
};
