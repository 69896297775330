import { FallbackWrapper } from './Fallback.styles';

const Fallback = () => {
  return (
    <FallbackWrapper>
      <svg
        width="40"
        height="98"
        viewBox="0 0 20 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5557 18.0032V2.55995C15.5557 1.14602 16.6785 0 18.0637 0H19.9222V26.1753C19.9222 29.365 18.9363 31.8723 16.9656 33.6972C14.9949 35.5233 12.6597 36.4369 9.96108 36.4369C7.10991 36.4369 4.71188 35.4031 2.76474 33.3354C0.934244 31.3914 -0.0651233 28.7032 0.00329582 26.007C0.0705933 23.3235 1.02397 21.0337 2.86568 19.1401C4.77581 17.1755 7.14131 16.192 9.9622 16.192C11.0233 16.192 12.0159 16.3397 12.9412 16.6328C13.8655 16.927 14.5407 17.2293 14.9658 17.5384L15.5557 18.0032ZM4.36529 26.315C4.36529 27.9865 4.90367 29.3799 5.97931 30.4938C7.05607 31.6078 8.38295 32.1653 9.95996 32.1653C11.5672 32.1653 12.9009 31.6078 13.963 30.4938C15.0241 29.3799 15.5557 27.9865 15.5557 26.315C15.5557 24.6126 15.0162 23.2124 13.9417 22.1122C12.8638 21.0142 11.537 20.4647 9.96108 20.4647C8.38407 20.4647 7.05719 21.0154 5.98043 22.1122C4.90367 23.2124 4.36529 24.6126 4.36529 26.315Z"
          fill="#1C2227"
        />
        <path
          className="bounce"
          d="M9.95994 48.1934C12.0339 48.1934 13.7151 46.4773 13.7151 44.3604C13.7151 42.2435 12.0339 40.5273 9.95994 40.5273C7.886 40.5273 6.20474 42.2435 6.20474 44.3604C6.20474 46.4773 7.886 48.1934 9.95994 48.1934Z"
          fill="#2DCCD3"
        />
      </svg>
    </FallbackWrapper>
  );
};

export default Fallback;
