import styled from 'styled-components';
import { Layout } from 'antd';

const { Header } = Layout;

const HeaderContainer = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0px 30px;
  background: #fff;
  @media (max-width: 767px) {
    padding: 0px 20px;
  }
  // box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  &.header-container-black {
    background: ${(props) => props.theme.styles.colorDarkBlue};
  }

  .header-logo {
    width: 165px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    a {
      cursor: pointer;
      color: #fff !important;
      text-decoration: none !important;

      &:first-child {
        margin-right: 20px;
        width: 100px;
        height: 37px;

        svg {
          width: 100px;
          height: 37px;
        }
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .btn-header {
    width: auto;
    border: none;
    box-shadow: none;
    @media (min-width: 575px) {
      width: 190px;
    }
  }

  @media (max-width: 768px) {
    padding: 0 20px 0 10px;
  }

  @media (max-width: 575px) {
    justify-content: center;
    padding: 0 20px;
  }
`;
export default HeaderContainer;
