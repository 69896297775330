import theme from 'styled-theming';

const styles = {
  fontSizeBiggest: theme('mode', {
    primary: '50px',
  }),
  fontSizeBigger: theme('mode', {
    primary: '34px',
  }),
  fontSizeBig: theme('mode', {
    primary: '28px',
  }),
  fontSizeMedium: theme('mode', {
    primary: '24px',
  }),
  fontSizeSmall: theme('mode', {
    primary: '16px',
  }),
  fontSizeSmallest: theme('mode', {
    primary: '14px',
  }),
  colorBlack: theme('mode', {
    primary: '#1C2227',
  }),
  colorDarkBlue: theme('mode', {
    primary: '#001C35',
  }),
  colorLightBlack: theme('mode', {
    primary: '#425466',
  }),
  colorActiveGreen: theme('mode', {
    primary: '#66CB9F',
  }),
  colorWhite: theme('mode', {
    primary: '#ffffff',
  }),
  colorLightBlue: theme('mode', {
    primary: '#2DCCD3',
  }),
  colorLightGreen: theme('mode', {
    primary: '#4DBC5D',
  }),

  colorBlue: theme('mode', {
    primary: '#00CFCF',
  }),
  colorYellow: theme('mode', {
    primary: '#FFAD00',
  }),
  colorPink: theme('mode', {
    primary: '#FF0064',
  }),
  colorGreen: theme('mode', {
    primary: '#71D63D',
  }),
  colorPurple: theme('mode', {
    primary: '#901D93',
  }),
  colorGray: theme('mode', {
    primary: '#d9d9d9',
  }),
  colorGrayLight: theme('mode', {
    primary: '#262626',
  }),
  colorGrayDark: theme('mode', {
    primary: '#5F6B8A',
  }),
  colorGrayDeep: theme('mode', {
    primary: '#F2F2F2',
  }),
  inputGray: theme('mode', {
    primary: '#C9C9C9',
  }),
  inputActiveGray: theme('mode', {
    primary: '#A0A0A0',
  }),

  fontOxanium: theme('mode', {
    primary: `'Oxanium', cursive`,
  }),
  fontNunitoSans: theme('mode', {
    primary: `Nunito Sans, sans-serif`,
  }),
  fontInter: theme('mode', {
    primary: `Inter`,
  }),
  fontOutfit: theme('mode', {
    primary: `Outfit`,
  }),
  fontProximaNova: theme('mode', {
    primary: `proxima-nova, sans-serif`,
  }),

  cardBoxShadowDefault: theme('mode', {
    primary: `0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05)`,
  }),
  cardBoxShadowHover: theme('mode', {
    primary: `0 5px 30px rgba(0, 0, 0, 0.15)`,
  }),
  cardBoxTransition: theme('mode', {
    primary: `all 0.3s cubic-bezier(0.645,0.045,0.355,1)`,
  }),
};

export default styles;
