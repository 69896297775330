import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logout } from 'src/state/ducks/user/actions';

import {
  CardMenuContainer,
  CardMenuItemContainer,
  LinkContainer,
} from './ProtectedHeader.style';
import Timer from 'src/components/ProtectedHeader/Timer';
import { Link } from 'src/components/common/Typography';

const CardMenu = ({ setVisible }) => {
  const { user, isAuthenticate } = useSelector(
    (store) => store.userState.currentUser
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignOut = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <CardMenuContainer>
        {isAuthenticate ? (
            user?.membership === false && user?.role !== 'guest' && (
                <CardMenuItemContainer className="free-trial-status-card-menu">
                    {user?.trialSeason === 0 ? (
                        'Update Your Plan'
                        ) : (
                        <Timer user={user} />
                    )}
                </CardMenuItemContainer>
            )
        ) : (
          ''
        )}
      <CardMenuItemContainer>
        <LinkContainer to="/account/settings" onClick={() => setVisible(false)}>
          My Account
        </LinkContainer>
      </CardMenuItemContainer>
      <CardMenuItemContainer>
        {user?.membership ? (
          <LinkContainer
            to="/account/billing-details"
            onClick={() => setVisible(false)}
          >
            Billing
          </LinkContainer>
        ) : (
          <LinkContainer to="/upgrade-plan" onClick={() => setVisible(false)}>
            Upgrade Plan
          </LinkContainer>
        )}
      </CardMenuItemContainer>
      <CardMenuItemContainer onClick={handleSignOut}>
        <LinkContainer to="#" onClick={() => setVisible(false)}>
          Sign out
        </LinkContainer>
      </CardMenuItemContainer>
    </CardMenuContainer>
  );
};

export default CardMenu;
