import { combineReducers } from 'redux';

import types from './types';

const singleProjectReducers = (state = { loading: true }, action) => {
  switch (action.type) {
    case types.ADD_SINGLE_PROJECT_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case types.ADD_COLLABORATORS:
      return {
        ...state,
        invitedCollaborators: [...action.payload],
      };
    default:
      return state;
  }
};

// export const invitedCollaborators = (state = { loading: true }, action) => {
//   switch (action.type) {
//     case types.ADD_COLLABORATORS:
//       return {
//         ...state,
//         loading: false,
//        action.payload,
//       };
//     default:
//       return state;
//   }
// };

const projectReducers = combineReducers({
  singleProjectReducers,
});

export default projectReducers;
