import { LinkContainer } from './Typography.style';

const Link = ({ className, children, ...restProps }) => {
  return (
    <LinkContainer className={`link-container ${className}`} {...restProps}>
      {children}
    </LinkContainer>
  );
};

export default Link;
