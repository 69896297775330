import jwt_decode from 'jwt-decode';
import types from './types';

export const setUserData = (payload) => (dispatch) =>
  dispatch({
    type: types.SET_USER_DATA,
    payload,
  });

export const setCurrentUser = (token) => (dispatch) => {
  if (token !== null || token !== undefined) {
    window.localStorage.setItem('token', token);
  }
  const decoded = jwt_decode(token);
  return dispatch({
    type: types.SET_CURRENT_USER,
    payload: {
      user: {
        ...decoded,
        ...(decoded?.siteName
          ? {
              siteName: decoded?.siteName?.toLowerCase(),
            }
          : {
              siteName: 'guest',
            }),
      },
      isAuthenticate: true,
    },
  });
};

export const logout = () => (dispatch) => {
  window.google?.accounts?.id.disableAutoSelect();
  localStorage.removeItem('token');
  return dispatch({
    type: types.USER_LOGOUT,
  });
};

export default {
  setCurrentUser,
  setUserData,
  logout,
};
