import { useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  ElementsConsumer,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

import { PaymentContainer, StripeContent } from './StripePayment.style';

const CARD_OPTIONS = {
  style: {
    base: {
      border: '1px solid #c9c9c9',
      ':-webkit-autofill': {
        color: 'black',
      },
      '::placeholder': {
        fontSize: '16px',
        lineHeight: '20px',
        color: '#262626',
      },
    },
    invalid: {
      iconColor: '#ff0064',
      color: '#ff0064',
    },
  },
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutForm = ({
  stripe,
  elements,
  onChange,
  errorMessage,
  expiryError,
  cvvError,
  cardId,
  paymentCardData,
  pathname,
}) => {
  const handleChange = async () => {
    try {
      const { token } = await stripe.createToken(
        elements.getElement(
          CardNumberElement,
          CardExpiryElement,
          CardCvcElement
        )
      );
      onChange(token.id);
    } catch (e) {
      //   console.log(e);._elements
    }
  };

  const checkLocation =
    pathname !== '/billing' || pathname !== '/add/payment-card';

  return (
    <PaymentContainer className={`${cardId && checkLocation && 'editPayment'}`}>
      <div>
        <StripeContent.Item
          className={`${cardId && checkLocation && 'disabled'}`}
          label="Card Number"
          name="card"
          rules={[
            {
              required: !cardId && true,
              message: 'Input your valid card',
            },
          ]}
          validateStatus="error"
          help={errorMessage}
        >
          <CardNumberElement
            options={{
              ...CARD_OPTIONS,
              placeholder:
                cardId && checkLocation
                  ? `**** **** **** ${paymentCardData?.last4}`
                  : '0000 0000 0000 0000',
              showIcon: true,
              iconStyle: 'solid',
              disabled: cardId && checkLocation,
            }}
            onBlur={handleChange}
          />
        </StripeContent.Item>
      </div>
      {!cardId && pathname !== `/payment-update/${cardId}` && (
        <Row gutter={12}>
          <Col sm={12} xs={24}>
            <div>
              <StripeContent.Item
                label="Expiry Date (MM / YY)"
                name="expiry"
                rules={[
                  {
                    required: true,
                    message: 'Input your valid expiry date',
                  },
                ]}
                validateStatus="error"
                help={expiryError}
              >
                <CardExpiryElement
                  options={{
                    ...CARD_OPTIONS,
                    placeholder: 'MM / YY',
                  }}
                  onBlur={handleChange}
                />
              </StripeContent.Item>
            </div>
          </Col>
          <Col sm={12} xs={24}>
            <div>
              <StripeContent.Item
                label="CVC / CVV"
                name="cvv"
                rules={[
                  {
                    required: true,
                    message: 'Input your cvc/cvv',
                  },
                ]}
                validateStatus="error"
                help={cvvError}
              >
                <CardCvcElement
                  options={{
                    ...CARD_OPTIONS,
                    placeholder: '3 or 4 digits',
                  }}
                  onBlur={handleChange}
                />
              </StripeContent.Item>
            </div>
          </Col>
        </Row>
      )}
    </PaymentContainer>
  );
};

const StripePaymentForm = ({
  setCardToken,
  errorMessage,
  expiryError,
  cvvError,
  cardId,
  paymentCardData,
}) => {
  const handleChange = (e) => {
    setCardToken(e);
  };

  const location = useLocation();
  const pathname = location?.pathname;

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <CheckoutForm
            stripe={stripe}
            elements={elements}
            onChange={handleChange}
            errorMessage={errorMessage}
            expiryError={expiryError}
            cvvError={cvvError}
            cardId={cardId}
            paymentCardData={paymentCardData}
            pathname={pathname}
          />
        )}
      </ElementsConsumer>
    </Elements>
  );
};

export default StripePaymentForm;
