import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

const PublicContent = styled(Content)`
  flex: 1 0 auto;
  min-height: calc(100vh - 140px);
  @media (max-width: 575px) {
    display: flex;
    padding: 0 20px;
    justify-content: center;
  }
`;

export default PublicContent;
