const UploadSolid = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4609 3.96094L12 3.44531L12.5391 3.96094L17.7891 9.21094L16.7109 10.2891L12.75 6.32812V18.75H11.25V6.32812L7.28906 10.2891L6.21094 9.21094L11.4609 3.96094ZM5.25 21.75V20.25H18.75V21.75H5.25Z"
        fill="black"
      />
    </svg>
  );
};

export default UploadSolid;
