const VideoIcon = () => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.75 29.1666L57.0296 22.5283C57.9335 22.0766 59.0067 22.125 59.8664 22.6561C60.726 23.1872 61.2495 24.1254 61.25 25.1358V44.8642C61.2495 45.8746 60.726 46.8128 59.8664 47.3439C59.0067 47.875 57.9335 47.9233 57.0296 47.4717L43.75 40.8333V29.1666Z"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="8.75"
        y="17.5"
        width="35"
        height="35"
        rx="2"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4166 35.0001H32.0833"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.25 29.1667V40.8334"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VideoIcon;
