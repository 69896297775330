const FileIcon = () => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6666 49.5835V55.4168C11.6666 58.6385 14.2783 61.2502 17.5 61.2502H52.5C55.7216 61.2502 58.3333 58.6385 58.3333 55.4168V49.5835"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4166 32.0835L35 46.6668L49.5833 32.0835"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 11.6665V46.6665"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileIcon;
