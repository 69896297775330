import AlertContainer from './Alert.styles';

const Alert = (props) => {
  const { message, type, onClick, disabled, ...restProps } = props;

  return (
    <AlertContainer
      message={message}
      type={type}
      showIcon
      action={
        type === 'warning' && (
          <button type="button" onClick={onClick} disabled={disabled}>
            Try Again
          </button>
        )
      }
      {...restProps}
    />
  );
};

export default Alert;
