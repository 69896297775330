const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const generateUrl = (imageUrl) => {
  if (imageUrl) {
    const chatAtImageUrl = imageUrl?.charAt(0);
    if (chatAtImageUrl === 'h') {
      return imageUrl;
    }

    return apiEndpoint + imageUrl;
  }
};

const getFirstTwoChar = (value) => {
  if (value) {
    if (value?.length < 2) return value?.toUpperCase();
    const getSplitValue = value?.split('');
    const finalValue = getSplitValue[0] + getSplitValue[1];
    return finalValue.toUpperCase();
  }
};

const isEmptyObject = (obj) => {
  return Object.keys(obj).length > 0;
};

const generateFavicon = (url) => {
  const { hostname } = new URL(url);
  return `https://www.google.com/s2/favicons?sz=64&domain=${hostname}`;
};

export { generateUrl, getFirstTwoChar, isEmptyObject, generateFavicon };
