const PlusIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.0625 0.6875V10.0625H0.6875V11.9375H10.0625V21.3125H11.9375V11.9375H21.3125V10.0625H11.9375V0.6875H10.0625Z" />
    </svg>
  );
};

export default PlusIcon;
