const SocialInstagram = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17C9.23772 17 7 14.7614 7 11.9995C7 9.23856 9.23772 7 12 7C14.7601 7 17 9.23856 17 11.9995C17 14.7614 14.7601 17 12 17ZM12.001 9C10.3437 9 9 10.3427 9 12C9 13.6563 10.3437 15 12.001 15C13.6583 15 15 13.6563 15 12C15 10.3427 13.6583 9 12.001 9Z"
        fill="#5F6B8A"
      />
      <circle cx="17" cy="7" r="1" fill="#5F6B8A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8914 3.47305C19.0998 3.93912 20.0544 4.89321 20.5232 6.0998C20.7911 6.80639 20.931 7.55589 20.944 8.31238C21 9.26746 21 9.57685 21 12.015C21 14.4531 20.987 14.7575 20.944 15.7176C20.929 16.4641 20.7881 17.2006 20.5252 17.8992C20.0564 19.1058 19.1018 20.0589 17.8934 20.5269C17.1937 20.7884 16.455 20.9301 15.7084 20.9451C14.7538 21 14.4409 21 12 21C9.55908 21 9.25422 20.987 8.29165 20.9451C7.54198 20.9212 6.80331 20.7675 6.10762 20.496C4.89816 20.0289 3.94358 19.0749 3.47479 17.8693C3.21191 17.1697 3.07097 16.4331 3.05598 15.6866C3 14.7315 3 14.4212 3 11.984C3 9.54591 3.01299 9.24152 3.05598 8.28144C3.07097 7.53493 3.21191 6.7984 3.47479 6.0998C3.94258 4.89321 4.89716 3.93912 6.10662 3.47305C6.80531 3.20958 7.54398 3.06886 8.28965 3.05389C9.24422 3 9.55709 3 11.998 3C14.4389 3 14.7438 3.01198 15.7064 3.05389C16.454 3.06986 17.1927 3.21058 17.8914 3.47305ZM17.0363 18.6543C17.7794 18.369 18.3666 17.7814 18.6549 17.0379C18.844 16.5269 18.9423 15.9874 18.9489 15.4443H18.9593C19 14.5412 19 14.2937 19 11.9924C19 9.69028 18.9896 9.43806 18.9489 8.53968C18.9404 8.00121 18.8402 7.46748 18.6549 6.96302C18.3666 6.21957 17.7794 5.63198 17.0363 5.34575C16.5305 5.15776 15.9973 5.05857 15.4575 5.05196C14.5432 5 14.3069 5 12.0028 5C9.69881 5 9.44638 5.01134 8.54727 5.05196C8.00932 5.05951 7.4742 5.1587 6.96934 5.34575C6.22339 5.63104 5.6325 6.21768 5.34414 6.96302C5.15505 7.47409 5.05673 8.01255 5.05011 8.55668C5 9.47206 5 9.70729 5 12.0094C5 14.3107 5.00945 14.5638 5.05011 15.4613C5.05862 15.9997 5.15883 16.5335 5.34414 17.0379C5.6325 17.7823 6.22339 18.3699 6.96934 18.6543C7.4742 18.8422 8.00932 18.9414 8.54727 18.948C9.46245 19 9.69787 19 12.0028 19C14.3059 19 14.5593 18.9896 15.4575 18.948C15.9973 18.9405 16.5305 18.8422 17.0363 18.6543Z"
        fill="#5F6B8A"
      />
    </svg>
  );
};

export default SocialInstagram;
