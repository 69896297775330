const LogoWhite = () => {
  return (
    <svg
      width="100"
      height="37"
      viewBox="0 0 100 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.1727 23.2155C48.1587 23.2298 48.1496 23.2475 48.1356 23.2618C47.3434 24.0699 46.3682 24.4731 45.2101 24.4731C44.0511 24.4731 43.0759 24.069 42.2845 23.2618C41.4932 22.4546 41.0975 21.4243 41.0975 20.1726C41.0975 18.9209 41.4932 17.8914 42.2837 17.0825C43.0751 16.2753 44.0502 15.8712 45.2092 15.8712C46.3674 15.8712 47.3426 16.2761 48.1348 17.0825C48.1488 17.0968 48.1587 17.1145 48.1727 17.1296L50.3489 14.9074C48.9236 13.4571 47.2115 12.7307 45.2101 12.7307C43.1814 12.7307 41.4536 13.4588 40.0275 14.915C38.6015 16.3721 37.8884 18.1238 37.8884 20.1717C37.8884 22.2433 38.6015 24.0017 40.0275 25.4461C41.4536 26.8914 43.1814 27.6145 45.2101 27.6145C47.2156 27.6145 48.9319 26.8914 50.358 25.447L48.1727 23.2155Z"
        fill="white"
      />
      <path
        d="M9.70301 23.1086C9.70301 24.4966 9.23479 25.5951 8.29918 26.4024C7.36358 27.2113 6.11472 27.6145 4.5551 27.6145C3.88658 27.6145 3.223 27.5286 2.56518 27.3586C1.90737 27.1877 1.41195 27.0118 1.0781 26.8291L0.576914 26.5556V23.3805C1.73509 24.1094 3.06143 24.4731 4.55593 24.4731C5.84764 24.4731 6.49474 24.0194 6.49474 23.1111C6.49474 22.702 6.28783 22.3561 5.87485 22.0724C5.46104 21.7879 4.95655 21.5194 4.36056 21.2635C3.76375 21.0093 3.16282 20.7517 2.55694 20.4916C1.95106 20.2323 1.44163 19.8014 1.02947 19.1978C0.617305 18.5951 0.4104 17.8611 0.4104 16.9958C0.4104 15.6759 0.883563 14.6355 1.83154 13.8721C2.77868 13.1094 3.97643 12.729 5.42559 12.729C6.11637 12.729 6.76841 12.8022 7.38171 12.9504C7.99501 13.0993 8.4352 13.2525 8.70228 13.4116L9.10373 13.6507V16.6894C8.12278 16.1431 6.89701 15.8704 5.42559 15.8704C4.22208 15.8704 3.62032 16.2357 3.62032 16.9672C3.62032 17.3224 3.83052 17.6204 4.25175 17.8645C4.67381 18.1086 5.17664 18.3561 5.76274 18.6061C6.34801 18.8569 6.94977 19.1145 7.56554 19.3788C8.18213 19.6431 8.69321 20.0985 9.09713 20.7466C9.50105 21.3956 9.70301 22.1827 9.70301 23.1086Z"
        fill="white"
      />
      <path
        d="M19.3055 24.303V27.4436H18.637C17.0098 27.4436 15.6843 26.9941 14.6588 26.0951C13.6333 25.1961 13.1206 23.7004 13.1206 21.6061V9.82578H16.2967V12.9007H19.0376V16.0413H16.2967V21.6052C16.2967 23.4032 17.0765 24.3022 18.637 24.3022H19.3055V24.303Z"
        fill="white"
      />
      <path
        d="M32.5006 27.4436V26.2828C32.3893 26.3737 32.2269 26.4933 32.0159 26.6414C31.8032 26.7896 31.3416 26.9882 30.6277 27.2382C29.9147 27.4882 29.1679 27.6136 28.3881 27.6136C26.3149 27.6136 24.5772 26.8906 23.1726 25.4453C21.7687 24.0008 21.0664 22.2424 21.0664 20.1709C21.0664 18.1229 21.7737 16.3704 23.1899 14.9141C24.6044 13.4579 26.3371 12.7298 28.3881 12.7298C30.3714 12.7298 32.0876 13.4015 33.536 14.7433C34.9843 16.0859 35.7089 17.9293 35.7089 20.2736V27.4428H32.5006V27.4436ZM24.2763 20.1726C24.2763 21.4242 24.672 22.4545 25.4625 23.2626C26.2539 24.0707 27.2291 24.4739 28.3881 24.4739C29.5462 24.4739 30.5214 24.0699 31.3136 23.2626C32.1041 22.4545 32.4998 21.4251 32.4998 20.1726C32.4998 18.9436 32.1091 17.9192 31.3292 17.1002C30.5486 16.2811 29.5685 15.8712 28.3872 15.8712C27.2282 15.8712 26.2531 16.2811 25.4617 17.1002C24.672 17.9192 24.2763 18.9436 24.2763 20.1726Z"
        fill="white"
      />
      <path
        d="M63.1183 27.4436V26.2828C63.007 26.3737 62.8446 26.4933 62.6336 26.6414C62.4209 26.7896 61.9593 26.9882 61.2454 27.2382C60.5316 27.4882 59.7855 27.6136 59.0057 27.6136C56.9326 27.6136 55.1949 26.8906 53.7902 25.4453C52.3864 24.0008 51.6841 22.2424 51.6841 20.1709C51.6841 18.1229 52.3914 16.3704 53.8075 14.9141C55.2221 13.4579 56.9548 12.7298 59.0057 12.7298C60.9891 12.7298 62.7053 13.4015 64.1536 14.7433C65.602 16.0859 66.3266 17.9293 66.3266 20.2736V27.4428H63.1183V27.4436ZM54.894 20.1726C54.894 21.4242 55.2897 22.4545 56.0802 23.2626C56.8716 24.0707 57.8467 24.4739 59.0057 24.4739C60.1639 24.4739 61.1391 24.0699 61.9313 23.2626C62.7218 22.4545 63.1175 21.4251 63.1175 20.1726C63.1175 18.9436 62.7267 17.9192 61.9469 17.1002C61.1663 16.2811 60.1862 15.8712 59.0049 15.8712C57.8459 15.8712 56.8707 16.2811 56.0794 17.1002C55.288 17.9192 54.894 18.9436 54.894 20.1726Z"
        fill="white"
      />
      <path
        d="M79.8002 14.0615V2.70708C79.8002 1.66751 80.6253 0.824921 81.6434 0.824921H83.0093V20.0699C83.0093 22.415 82.2847 24.2584 80.8364 25.6002C79.388 26.9428 77.6718 27.6145 75.6885 27.6145C73.593 27.6145 71.8306 26.8544 70.3996 25.3342C69.0543 23.9049 68.3198 21.9285 68.3701 19.9461C68.4196 17.9731 69.1202 16.2896 70.4738 14.8973C71.8776 13.4529 73.6161 12.7298 75.6893 12.7298C76.4691 12.7298 77.1986 12.8384 77.8787 13.0539C78.5579 13.2702 79.0542 13.4924 79.3666 13.7197L79.8002 14.0615ZM71.5759 20.1726C71.5759 21.4015 71.9716 22.4259 72.7621 23.245C73.5535 24.064 74.5286 24.4739 75.6876 24.4739C76.8689 24.4739 77.849 24.064 78.6297 23.245C79.4095 22.4259 79.8002 21.4015 79.8002 20.1726C79.8002 18.9209 79.4037 17.8914 78.614 17.0825C77.8218 16.2753 76.8466 15.8712 75.6885 15.8712C74.5295 15.8712 73.5543 16.2761 72.7629 17.0825C71.9716 17.8914 71.5759 18.9209 71.5759 20.1726Z"
        fill="white"
      />
      <path
        d="M84.9819 20.1726C84.9819 18.1246 85.695 16.372 87.121 14.9158C88.5471 13.4596 90.2749 12.7315 92.3036 12.7315C94.3091 12.7315 96.0254 13.4596 97.4515 14.9158C98.8775 16.3729 99.5906 18.1246 99.5906 20.1726C99.5906 22.2441 98.8775 24.0025 97.4515 25.447C96.0246 26.8922 94.3091 27.6153 92.3036 27.6153C90.2749 27.6153 88.5471 26.8922 87.121 25.447C85.695 24.0025 84.9819 22.2441 84.9819 20.1726ZM89.378 17.0825C88.5867 17.8914 88.1919 18.9209 88.1919 20.1726C88.1919 21.4242 88.5875 22.4545 89.378 23.2626C90.1694 24.0707 91.1446 24.4739 92.3036 24.4739C93.4617 24.4739 94.4369 24.0699 95.2291 23.2626C96.0196 22.4545 96.4153 21.4251 96.4153 20.1726C96.4153 18.9209 96.0188 17.8914 95.2291 17.0825C94.4377 16.2752 93.4617 15.8712 92.3036 15.8712C91.1446 15.8712 90.1686 16.2752 89.378 17.0825Z"
        fill="white"
      />
      <path
        d="M75.6886 36.2584C77.2128 36.2584 78.4484 34.9967 78.4484 33.4403C78.4484 31.8838 77.2128 30.6221 75.6886 30.6221C74.1643 30.6221 72.9287 31.8838 72.9287 33.4403C72.9287 34.9967 74.1643 36.2584 75.6886 36.2584Z"
        fill="#2DCCD3"
      />
    </svg>
  );
};

export default LogoWhite;
