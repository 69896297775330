import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { markTrialAsEnded } from 'src/api';
import { logout } from 'src/state/ducks/user/actions';

/**
 * This method calculate the duration
 * @param {moment Object} eventTime - Time when the trial should end
 * @returns Moment objedct
 */
const calculateDuration = (eventTime) => {
  const _eventTime = moment(eventTime).valueOf() / 1000;
  return moment.duration(
    Math.max(_eventTime - Math.floor(Date.now() / 1000), 0),
    'seconds'
  );
};

/**
 * Time is count down that runs from createdAt of user
 * @param {Object} {user} - current logged in user
 * @returns JSX.Element
 */
const Timer = ({ user }) => {
  const interval = 1000;
  const { createdAt, role } = user;
  const endDate = moment(createdAt).add(user.trialSeason, 'days');
  const [duration, setDuration] = useState(calculateDuration(endDate));
  const timerRef = useRef(0);
  const [isTrailEnded, setIsTrialEnded] = useState(false);
  const dispatch = useDispatch();

  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(endDate));
  }, [endDate]);

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval);
    return () => {
      clearInterval(timerRef.current);
    };
  }, [endDate]);

  const updateUserTrialInfo = async () => {
    try {
      const getToken = localStorage.getItem('token');
      if (getToken !== undefined) {
        const decoded = jwtDecode(getToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          return dispatch(logout());
        }
        await markTrialAsEnded(getToken);
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    } catch (error) {
      dispatch(logout());
    }
  };

  useEffect(() => {
    if (duration.days() === 0 && duration.hours() === 0 && !isTrailEnded && role !== 'guest') {
      updateUserTrialInfo();
      setIsTrialEnded(true);
    }
  }, [duration]);

  return (
    <>
      {`${
        duration.days()
          ? `${duration.days()} days`
          : `${duration.hours()} hours`
      } left in trial`}{' '}
    </>
  );
};

export default Timer;
