/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux';

import types from './types';

const initialState = { user: null };

// eslint-disable-next-line default-param-last
const currentUser = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_CURRENT_USER:
      return {
        ...state,
        ...action.payload,
      };
    case types.USER_LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticate: false,
      };
    default:
      return state;
  }
};

const userReducer = combineReducers({
  currentUser,
});

export default userReducer;
