const LinkIcon = () => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.0833 20.4165H17.5C14.2783 20.4165 11.6666 23.0282 11.6666 26.2498V52.4998C11.6666 55.7215 14.2783 58.3332 17.5 58.3332H43.75C46.9716 58.3332 49.5833 55.7215 49.5833 52.4998V37.9165"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.1666 40.8332L58.3333 11.6665"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.75 11.6665H58.3333V26.2498"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;
