import PublicHeader from 'src/components/PublicHeader/PublicHeader';
import PublicFooter from 'src/components/PublicFooter/PublicFooter';

import PublicContent from './PublicLayout.style';

const PublicLayout = ({ children }) => {
  return (
    <>
      <PublicHeader />
      <PublicContent>{children}</PublicContent>
      <PublicFooter />
    </>
  );
};

export default PublicLayout;
