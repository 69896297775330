const PlusBackSmall = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 0.5V5.5H0.5V6.5H5.5V11.5H6.5V6.5H11.5V5.5H6.5V0.5H5.5Z"
        fill="black"
      />
    </svg>
  );
};

export default PlusBackSmall;
