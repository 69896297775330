import { useState, useEffect } from 'react';
import { Form, Radio, Space } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import csc from 'country-state-city';

import {
  billing,
  checkMe,
  deleteAllUserTeams,
  restoreAllUserTeams,
} from 'src/api';
import { logout, setCurrentUser } from 'src/state/ducks/user/actions';
import { Input } from 'src/components/common/Input/Input';
import Button from 'src/components/common/Button/Button';
import BasicSelect from 'src/components/common/Select/Select';
import StripePayment from 'src/form/StripePaymentForm/StripePaymentForm';
import jwtDecode from 'jwt-decode';
import Alert from 'src/components/common/Alert/Alert';

import { FormTitle, BillingFormContainer } from './BillingForm.styles';

const BillingForm = () => {
  const location = useLocation();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [cardToken, setCardToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [expiryError, setExpiryError] = useState(null);
  const [cvvError, setCvvError] = useState(null);
  const [deleteTeamOption, setDeleteTeamOption] = useState(0);
  const history = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.userState.currentUser);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const cardEmpty = values?.card?.empty;
    const cardComplete = values?.card?.complete;
    const expiryEmpty = values?.expiry?.empty;
    const expiryComplete = values?.expiry?.complete;
    const cvvEmpty = values?.cvv?.empty;
    const cvvComplete = values?.cvv?.complete;

    if (cardEmpty || !cardComplete) {
      setErrorMessage(values?.card?.error?.message);
      return;
    }
    setErrorMessage('');

    if (expiryEmpty || !expiryComplete) {
      setExpiryError(values?.expiry?.error?.message);
      return;
    }
    setExpiryError('');

    if (cvvEmpty || !cvvComplete) {
      setCvvError(values?.cvv?.error?.message);
      return;
    }
    setCvvError('');

    const billingInformation = {
      ...(cardToken && { sourceToken: cardToken }),
      ...(values?.cardName && {
        cardName: values?.cardName,
      }),
      ...(values?.country && {
        country: values?.country?.name,
      }),
    };

    try {
      const token = localStorage.getItem('token');
      setLoading(true);
      const { data } = await billing(
        {
          ...billingInformation,
          billingCycle: location?.state?.billingCycle || 'mo',
        },
        token
      );
      if (deleteTeamOption) {
        await deleteAllUserTeams();
      }
      if (!deleteTeamOption) {
        await restoreAllUserTeams();
      }
      const newToken = data?.token;
      const message = data?.message;
      dispatch(setCurrentUser(newToken));
      setSuccess(message);
      setError(null);
      setLoading(false);
      history('/');
    } catch (e) {
      const { data } = e.response;
      const message = data?.message;
      console.debug(data);
      setError(message);
      setSuccess(null);
      setLoading(false);
    }
  };

  const handleDeleteTeamOption = (e) => {
    setDeleteTeamOption(e.target.value);
  };

  const addressFromik = useFormik({
    initialValues: {
      country: 'US',
      state: null,
      city: null,
    },
  });

  const countries = csc.getAllCountries();

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.id,
    ...country,
  }));

  const { values, setValues } = addressFromik;

  /**
   * Fetch current user details
   * @returns Promise
   */
  const fetchCurrentUser = async () => {
    try {
      const getToken = localStorage.getItem('token');
      if (getToken !== undefined) {
        const decoded = jwtDecode(getToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          setLoading(false);
          return dispatch(logout());
        }
      }
      const { data } = await checkMe(getToken);
      const _token = data?.token;
      dispatch(setCurrentUser(_token));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error', err);
    }
  };

  useEffect(() => {}, [values]);

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  return (
    <BillingFormContainer form={form} onFinish={onFinish} scrollToFirstError>
      <div className="title">
        Upgrade your <br />
        <span>Stacado</span> Account
      </div>
      {error !== null && <Alert message={error} type="error" />}
      {success !== null && <Alert message={success} type="success" />}
      <FormTitle>Payment Method</FormTitle>
      <BillingFormContainer.Item
        label="Name on Card"
        name="cardName"
        className="input-wrapper"
        rules={[
          {
            required: true,
            message: 'Please enter your name on card',
          },
          {
            max: 20,
            message: 'Please provide less than 20 character',
          },
        ]}
      >
        <Input type="text" placeholder="Example: Melany Carter" />
      </BillingFormContainer.Item>
      <StripePayment
        setCardToken={setCardToken}
        errorMessage={errorMessage}
        expiryError={expiryError}
        cvvError={cvvError}
      />
      <BillingFormContainer.Item
        label="Country"
        name="country"
        rules={[
          {
            required: true,
            message: 'Select your country',
          },
        ]}
      >
        <BasicSelect
          id="country"
          name="country"
          label="country"
          options={updatedCountries}
          value={values.country}
          onChange={(value) => {
            setValues({ country: value, state: null, city: null }, false);
          }}
        />
      </BillingFormContainer.Item>
      {user?.subscriptionStatus === 'canceled' && (
        <Radio.Group onChange={handleDeleteTeamOption} value={deleteTeamOption}>
          <Space direction="vertical">
            <Radio value={0}>Keep my previous teams and projects</Radio>
            <Radio value={1}>Delete my all teams and projects </Radio>
          </Space>
        </Radio.Group>
      )}
      <BillingFormContainer.Item shouldUpdate>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-login"
          loading={loading}
          disabled={loading}
        >
          Upgrade Now
        </Button>
      </BillingFormContainer.Item>
    </BillingFormContainer>
  );
};

export default BillingForm;
