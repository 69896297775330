/* eslint-disable no-console */
import PublicFooter from 'src/components/PublicFooter/PublicFooter';
import ProtectedHeader from 'src/components/ProtectedHeader/ProtectedHeader';
import { useSelector } from 'react-redux';
import NoProUserModal from 'src/pages/NoProUserModal/NoProUserModal';
import { useLocation } from 'react-router-dom';
import Billing from 'src/pages/Billing/Billing';
import { ProtectedContent } from './ProtectedLayout.style';

const ProtectedLayout = ({ children }) => {
  const { user } = useSelector((store) => store.userState.currentUser);
  const location = useLocation();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {user?.trialSeason === 0 && !user?.membership && user?.role !== 'guest' ? (
        <>
          <ProtectedHeader />
          {location.pathname === '/billing' ? <Billing /> : <NoProUserModal />}
        </>
      ) : (
        <>
          <ProtectedHeader />
          <ProtectedContent>{children}</ProtectedContent>
          <PublicFooter />
        </>
      )}
    </>
  );
};

export default ProtectedLayout;
