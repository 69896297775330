const ADD_INVITED_MEMBERS = 'ADD_INVITED_MEMBERS';
const REMOVE_INVITED_MEMBER = 'REMOVE_INVITED_MEMBER';

const ADD_SINGLE_TEAM_DATA = 'ADD_SINGLE_TEAM_DATA';

export default {
  ADD_INVITED_MEMBERS,
  REMOVE_INVITED_MEMBER,
  ADD_SINGLE_TEAM_DATA,
};
