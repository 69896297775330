const Back = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.51562 0.140625L1.01562 5.64062L0.671875 6L1.01562 6.35938L6.51562 11.8594L7.23438 11.1406L2.09375 6L7.23438 0.859375L6.51562 0.140625Z"
        fill="white"
      />
    </svg>
  );
};

export default Back;
