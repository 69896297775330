import styled from 'styled-components';
import { Layout, Menu, Dropdown, Avatar, Badge } from 'antd';

import { Link } from 'src/components/common/Typography';

const { Header } = Layout;
const { Item } = Menu;

export const HeaderContainer = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 70px;
  padding: 0px 20px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  @media (max-width: 767px) {
    padding: 0px 20px;
  }
  .header-logo {
    width: 165px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    a {
      cursor: pointer;
      color: #fff !important;
      text-decoration: none !important;

      &:first-child {
        margin-right: 20px;
        width: 100px;
        height: 37px;

        svg {
          width: 100px;
          height: 37px;
        }
      }
    }
    .team-link {
      padding-top: 4px;
    }
  }
  &.header-container-black {
    background: ${(props) => props.theme.styles.colorDarkBlue};
  }
  @media (max-width: 575px) {
    padding: 0px 20px;
  }
  a {
    display: flex;
    align-items: center;
    height: 100%;
    svg {
      width: 100%;
    }
  }

  .btn-header {
    border: none;
    box-shadow: none;
    @media (min-width: 575px) {
      width: 190px;
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    margin-left: auto;
    .avatar {
      width: 40px;
      height: 40px;
      border: 1px solid white;
      border-radius: 50%;
      background: gray;
      margin-left: 24.5px;
    }
  }

  .free-trial-status {
    @media (max-width: 575px) {
      display: none;
    }
  }
`;

export const CardMenuContainer = styled(Menu)`
  width: 180px;
  height: 100%;
  background: ${(props) => props.theme.styles.colorWhite};
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;

  .free-trial-status-card-menu {
      background-color: #ffe58f;
      margin-top: 0;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      color: black;
      margin-right: 10px;
      font-weight: 700;
    @media (min-width: 575px) {
      display: none;
    }
  }
`;

export const CardMenuItemContainer = styled(Item)`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background: none;
  border: none;
  font-family: ${(props) => props.theme.styles.fontNunitoSans};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #999999;
  border-left: 2px solid transparent;
  &:hover {
    border-left: 2px solid ${(props) => props.theme.styles.colorLightBlue};
  }
  @media (max-width: 575px) {
    height: 28px !important;
  }
`;

export const DropdownContainer = styled(Dropdown)``;
export const CustromAvatar = styled(Avatar)`
  cursor: pointer;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-left: 24.5px;
  background-color: ${(props) => props.color || '#ffbf00'};
  display: flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 16px;
`;

export const CustromBadge = styled(Badge)`
  .ant-badge-dot {
    width: 8.5px;
    min-width: 8.5px;
    height: 8px;
    background: ${(props) => props.theme.styles.colorActiveGreen};
    box-shadow: 0 0 0 3px #fff;
    top: 6px;
    right: 8px;
  }
`;

export const LinkContainer = styled(Link)`
  font-family: ${(props) => props.theme.styles.fontNunitoSans};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #999999 !important;
  text-decoration: none !important;
  &:hover {
    color: #262626 !important;
  }
`;
