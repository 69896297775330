const PlusIconRonded = () => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="70"
        width="70"
        height="70"
        rx="35"
        transform="rotate(-90 0 70)"
        fill="#E4ECF7"
      />
      <path
        d="M23.3333 35.0002L46.6666 35.0002"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.0001 46.6665L35.0001 23.3332"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusIconRonded;
