import styled from 'styled-components';
import Modal from 'src/components/common/Modal/Modal';
import styles from 'src/theme';

const ModalContainer = styled(Modal)`
  width: 100% !important;
  max-width: 100vw;
  top: 0;
  max-height: 100%;
  height: 100vh;
  margin: 0;

  background-color: ${(props) => props.theme.styles.colorWhite} !important;

  .ant-modal-content {
    box-shadow: none !important;

    .ant-modal-header {
      padding: 20px 30px;
      border: none;
    }

    .ant-modal-footer {
      display: none;
    }

    .ant-modal-body {
      width: 740px;
      margin: 0 auto;
      padding: 0;
      @media (max-width: 767px) {
        width: 100%;
      }
      @media (max-width: 575px) {
        width: 100% !important;
        padding: 20px 30px !important;
        @media (max-width: 430px) {
          padding: 20px 15px !important;
        }
      }

      > h2.ant-typography {
        font-size: 40px !important;
        margin-bottom: 30px !important;
      }

      .title {
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: 60px;
        @media (max-width: 575px) {
          text-align: center;
          margin-bottom: 50px;
        }
      }

      .button-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width: 767px) {
          flex-wrap: wrap;
          margin-bottom: 30px;
        }
        @media (max-width: 670px) {
          .btn-card:nth-child(2n) {
            margin-right: 0px;
          }
        }

        .btn-card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 220px;
          height: 220px;
          box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
            0px 3px 8px -1px rgba(50, 50, 71, 0.05);
          border-radius: 10px;
          margin-bottom: 40px;
          box-sizing: border-box;

          &:not(.btn-card-video):hover {
            color: ${(props) => props.theme.styles.colorWhite} !important;
            box-shadow: 0 5px 30px rgba(0, 0, 0, 0.15) !important;
            opacity: 1 !important;
          }

          @media (max-width: 767px) {
            margin: 0px 20px 0px 0px;
            margin-bottom: 20px;
          }
          @media (max-width: 575px) {
            flex: calc(50% - 1rem);
            &:nth-child(2n-1) {
              margin-right: 10px;
            }
          }
          svg {
            margin-bottom: 20px;
            @media (max-width: 575px) {
              width: 40px;
              height: 40px;
            }
          }

          .title {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 10px;
            @media (max-width: 575px) {
              font-weight: 700;
              font-size: 22px;
            }
          }

          .description {
            font-family: ${styles.fontNunitoSans};
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            white-space: break-spaces;
          }
        }
      }
      .btn-card-link {
        background: ${styles.colorPink};
        border-color: ${styles.colorPink};
        @media (max-width: 430px) {
          margin-bottom: 20px !important;
        }
      }
      .btn-card-card {
        background: ${styles.colorYellow};
        border-color: ${styles.colorYellow};
      }

      .btn-card-file {
        background: ${styles.colorGreen};
        border-color: ${styles.colorGreen};
        @media (max-width: 430px) {
          margin-bottom: 20px !important;
        }
      }

      .btn-card-contact {
        background: ${styles.colorBlue};
        border-color: ${styles.colorBlue};
      }
      .btn-card-code {
        background: #901d93;
        border-color: #901d93;
      }
      .btn-card-video {
        background: #8492a6;
        opacity: 0.2;
        border-color: #8492a6;
      }
    }
  }

  .mb-40 {
    margin-bottom: 40px;
    @media (max-width: 430px) {
      margin-bottom: 20px !important;
    }
  }

  .mr-40 {
    margin-right: 40px;
  }
`;
export default ModalContainer;
