const SocialLinkedin = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5.82602" cy="5.85067" r="2.07284" fill="#5F6B8A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.85609 20.9943V9.49417H13.2837V11.0668H13.332C13.8085 10.164 14.9738 9.20996 16.7122 9.20996C20.333 9.20996 21 11.5907 21 14.6886V20.9952H17.4275V15.402C17.4275 14.069 17.4047 12.3533 15.5697 12.3533C13.709 12.3533 13.4267 13.8066 13.4267 15.3072V20.9943H9.85609ZM7.61367 9.49417H4.03735V20.9943H7.61367V9.49417Z"
        fill="#5F6B8A"
      />
    </svg>
  );
};

export default SocialLinkedin;
