import ButtonContainer from './Button.style';

const Button = ({ className, children, ...restProps }) => {
  return (
    <ButtonContainer className={`button-container ${className}`} {...restProps}>
      {children}
    </ButtonContainer>
  );
};

export default Button;
