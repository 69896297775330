import styled from 'styled-components';
import { Button } from 'antd';

const ButtonContainer = styled(Button)`
  &.ant-btn {
    font-family: ${(props) => props.theme.styles.fontProximaNova};
    letter-spacing: 0.32px;
    padding: 13px 28px;
    height: unset;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    color: ${(props) => props.theme.styles.colorGrayDark} !important;
    background: ${(props) => props.theme.styles.colorWhite};
    border: 1px solid ${(props) => props.theme.styles.colorGrayDark};
    border-radius: 4px;
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
      0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
    text-shadow: none;
    -webkit-appearance: none;
    opacity: 1;
    outline: none;

     &.ant-btn-sm {
        padding: 5px 24px;
        font-size: 14px;
      }
      &.ant-btn-lg {
        padding: 16px 32px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.4px;
      }

    svg {
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &:hover {
      color: ${(props) => props.theme.styles.colorGrayDark} !important;
      background: rgba(95, 107, 138, 0.15);
      border: 1px solid ${(props) => props.theme.styles.colorGrayDark};
    }

    &:focus {
      color: ${(props) => props.theme.styles.colorGrayDark} !important;
      background: rgba(95, 107, 138, 0.05);
    }

    &:active {
      color: ${(props) => props.theme.styles.colorGrayDark} !important;
      border: 1px solid ${(props) => props.theme.styles.colorGrayDark};
      box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.2);
      outline: none;
    }

    &[disabled] {
      color: ${(props) => props.theme.styles.colorGrayDark} !important;
      background: ${(props) => props.theme.styles.colorWhite};
      opacity: 0.5;
      border: 1px solid ${(props) => props.theme.styles.colorGrayDark};
    }

    &.ant-btn-primary {
      color: ${(props) => props.theme.styles.colorWhite} !important;
      background: linear-gradient(83.26deg, #2dccd3 0%, #2dccd3 100%);
      border: 1px solid #2dccd3;
   
${
  '' /* 
      &.ant-btn-sm {
        padding: 5px 24px;
        font-size: 14px;
      }
      &.ant-btn-lg {
        padding: 16px 32px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.4px;
      } */
}

      &:hover {
        color: ${(props) => props.theme.styles.colorWhite} !important;
        background: linear-gradient(83.26deg, #14a4aa 0%, #14a4aa 100%);
        border: 1px solid #2dccd3;
      }

      &:focus {
        color: ${(props) => props.theme.styles.colorWhite} !important;
        background: linear-gradient(83.26deg, #28b8be 0%, ##2dccd3 100%);
        border: 1px solid #28b8be;
        outline: none;
      }

      &:active {
        color: ${(props) => props.theme.styles.colorWhite} !important;
        background: linear-gradient(83.26deg, #2dccd3 0%, #2dccd3 100%);
        border: 1px solid #2dccd3;
        box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.2);
        outline: none;
      }

      &[disabled] {
        color: ${(props) => props.theme.styles.colorWhite} !important;
        background: linear-gradient(83.26deg, #2dccd3 0%, #2dccd3 100%);
        opacity: 0.5;
        outline: 1px solid #2dccd3;
      }
    }
    &.deleteBtn{
      color:red
    }
    &.ant-btn-secondary {
      color: ${(props) => props.theme.styles.colorWhite} !important;
      border: 1px solid #12874b !important;
      background: ${(props) => props.theme.styles.colorLightGreen};

      ${
        '' /* &.ant-btn-sm {
        height: 32px;
        padding: 0px 24px;
        font-size: 16px;
      }

      &.ant-btn-lg {
        height: 56px;
        padding: 0px 32px;
        font-size: 18px;
      } */
      }

      &:hover {
        color: ${(props) => props.theme.styles.colorWhite} !important;
        background: rgba(77, 188, 93, 0.8);
      }

      &:focus {
        color: ${(props) => props.theme.styles.colorWhite} !important;
        background: #4dbc5d;
        border: 1px solid #12874b;
        outline: 4px solid #d9e8e5;
      }

      &:active {
        color: ${(props) => props.theme.styles.colorWhite} !important;
        background: #4dbc5d;
        border: 1px solid #12874b;
        outline: none;
      }

      &[disabled] {
        background: #4dbc5d;
        opacity: 0.5;
        border: 1px solid #12874b;
        outline: none;
      }
    }
    &.ant-btn-danger {
      color: ${(props) => props.theme.styles.colorWhite} !important;
      background: ${(props) => props.theme.styles.colorPink};
      border: 1px solid ${(props) => props.theme.styles.colorPink}; !important;

      ${
        '' /* &.ant-btn-sm {
        padding: 5px 24px;
        font-size: 14px;
      }
      &.ant-btn-lg {
        padding: 16px 32px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.4px;
      } */
      }

      &:hover {
        color: ${(props) => props.theme.styles.colorWhite} !important;
        background: #d20046;
        outline: 1px solid ${(props) => props.theme.styles.colorPink};

        & > svg {
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
      }

      &:focus {
        color: ${(props) => props.theme.styles.colorWhite};
        background: ${(props) => props.theme.styles.colorPink};
        border: 1px solid #e6005a !important;
        outline: 4px solid #e6005a;
      }

      &:active {
        color: ${(props) => props.theme.styles.colorWhite};
        background: ${(props) => props.theme.styles.colorPink};
        border: 1px solid ${(props) => props.theme.styles.colorPink};
        box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.2);
        outline: 0;
      }

      &[disabled] {
        color: ${(props) => props.theme.styles.colorWhite} !important;
        background: ${(props) => props.theme.styles.colorPink};
        opacity: 0.5;
        border: 1px solid ${(props) => props.theme.styles.colorPink};
      }
    }
  }
`;

export default ButtonContainer;
