// import Button from 'src/components/common/Button/Button';
// import { ReactComponent as LogoBlack } from 'src/assets/icons/stacado_white.svg';
// import { Link } from 'src/components/common/Typography';

import HeaderContainer from './PublicHeader.style';

const Header = () => {
  return (
    <HeaderContainer>
      {/* <Link to="/">
        <LogoBlack />
      </Link>
      <Link to="/signup">
        <Button className="btn-header" type="primary">
          Try For Free
        </Button>
      </Link> */}
    </HeaderContainer>
  );
};
export default Header;
