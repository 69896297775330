const PenSolid = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 26 26"
      fill="#999999"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2.1875C18.8125 1.375 19.8594 0.96875 20.9062 0.96875C21.9531 0.96875 23 1.375 23.8125 2.1875C25.4375 3.8125 25.4375 6.375 23.8125 8L8 23.8125L7.6875 23.875L2.1875 24.9688L0.71875 25.2812L1.03125 23.8125L2.125 18.3125L2.1875 18L18 2.1875ZM22.4062 3.59375C21.918 3.10547 21.4102 2.875 20.9062 2.875C20.4023 2.875 19.8945 3.10547 19.4062 3.59375L18.7188 4.3125L21.6875 7.28125L22.4062 6.59375C23.3789 5.62109 23.3789 4.56641 22.4062 3.59375ZM17.3125 5.71875L20.2812 8.6875L8.1875 20.7812C7.53125 19.5 6.5 18.4688 5.21875 17.8125L17.3125 5.71875ZM6.5625 22.0625C6.07812 20.8633 5.13672 19.9219 3.9375 19.4375L3.28125 22.7188L6.5625 22.0625Z"
      />
    </svg>
  );
};

export default PenSolid;
