const CopyUrl = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3V18H8.25V16.5H4.5V4.5H13.5V5.25H15V3H3ZM9 6V21H21V6H9ZM19.5 19.5V7.5H10.5V19.5H19.5Z"
      />
    </svg>
  );
};

export default CopyUrl;
