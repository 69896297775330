const LockIcon = ({ className }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0.25C5.11719 0.25 2.75 2.61719 2.75 5.5V7.75H0.5V19.75H15.5V7.75H13.25V5.5C13.25 2.61719 10.8828 0.25 8 0.25ZM8 1.75C10.0654 1.75 11.75 3.43457 11.75 5.5V7.75H4.25V5.5C4.25 3.43457 5.93457 1.75 8 1.75ZM14 18.25V9.25H2V18.25H14Z"
        fill="black"
      />
    </svg>
  );
};

export default LockIcon;
