const DinersClub = () => {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V14C24 15.1046 23.1046 16 22 16H2C0.89543 16 0 15.1046 0 14V2Z"
        fill="#CCECFF"
      />
      <path
        d="M19.5582 8.05217C19.5582 4.47286 16.5707 1.99892 13.2972 2H10.4803C7.16783 1.99881 4.44141 4.47361 4.44141 8.05217C4.44141 11.326 7.16791 14.0158 10.4803 13.9999H13.2972C16.5704 14.0157 19.5582 11.3251 19.5582 8.05217Z"
        fill="#0079BE"
      />
      <path
        d="M10.498 2.50699C7.47125 2.50792 5.01866 4.96631 5.01758 8.00039C5.01833 11.034 7.47103 13.492 10.498 13.4931C13.5255 13.4922 15.9788 11.034 15.9792 8.00039C15.9788 4.96624 13.5255 2.50807 10.498 2.50699Z"
        fill="white"
      />
      <path
        d="M7.03418 7.98554C7.03706 6.50292 7.96116 5.23863 9.26423 4.73624V11.2341C7.96116 10.732 7.03702 9.46838 7.03418 7.98558V7.98554ZM11.7506 11.2357V4.73642C13.0542 5.23762 13.9798 6.50263 13.9821 7.98594C13.9797 9.46971 13.0542 10.7338 11.7506 11.2359V11.2357Z"
        fill="#0079BE"
      />
    </svg>
  );
};

export default DinersClub;
