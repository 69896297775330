import types from './types';

export const addSingleProjectData = (payload) => (dispatch) => {
  return dispatch({
    type: types.ADD_SINGLE_PROJECT_DATA,
    payload,
  });
};

export const addCollabortors = (payload) => (dispatch) => {
  return dispatch({
    type: types.ADD_COLLABORATORS,
    payload,
  });
};

export default {
  addSingleProjectData,
  addCollabortors,
};
