/* eslint-disable no-console */
import { Text } from 'src/components/common/Typography';
import { ReactComponent as LogoIcon } from 'src/assets/icons/stacado_icon.svg';
import {
  SocialInstagram,
  SocialLinkedin,
  SocialTwitter,
} from 'src/components/icons';

import { FooterContainer, FooterWrapper } from './PublicFooter.style';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper>
      <FooterContainer>
        <div className="rights">
          <a href="https://stacado.co" target="_blank" rel="noreferrer">
            <LogoIcon />
          </a>
          <Text fontWeight="400" fontSize="14px">
            {currentYear} Stacado. All rights reserved.
          </Text>
        </div>
        <div className="terms">
          <div className="row_1">
            <div>
              <a
                href="https://app.termly.io/document/terms-of-use-for-saas/d794db5d-88ca-4db2-96b7-020d0675a6ab"
                target="_blank"
                rel="noreferrer"
              >
                <Text fontWeight="400" fontSize="14px">
                  Terms of Use
                </Text>
              </a>
            </div>
            <div>|</div>
            <div>
              <a
                href="https://app.termly.io/document/privacy-policy/efaa4998-2a0c-4eb0-bb7d-e2bfba73cb09"
                target="_blank"
                rel="noreferrer"
              >
                <Text fontWeight="400" fontSize="14px">
                  Privacy Policy
                </Text>
              </a>
            </div>
          </div>
          <ul className="social">
            <li>
              <a
                href="https://www.instagram.com/staca.do/"
                target="_blank"
                rel="noreferrer"
              >
                <SocialInstagram />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/stacado/"
                target="_blank"
                rel="noreferrer"
              >
                <SocialLinkedin />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/stacadoapp"
                target="_blank"
                rel="noreferrer"
              >
                <SocialTwitter />
              </a>
            </li>
          </ul>
        </div>
      </FooterContainer>
      <div className="footer-space" />
    </FooterWrapper>
  );
};

export default Footer;
