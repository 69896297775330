import styled from 'styled-components';

import Modal from 'src/components/common/Modal/Modal';

const NoProUserModalContainer = styled(Modal)`
  width: 100% !important;
  max-width: 100vw;
  top: 0;
  max-height: 100%;
  height: 100vh;
  margin: 0;

  background-color: ${(props) => props.theme.styles.colorWhite} !important;
  .ant-modal-header {
    padding-top: 20px;
  }
  .ant-modal-content {
    box-shadow: none !important;
  }
  .ant-modal-close-x {
    margin-top: 30px;
  }
  .ant-card {
    margin: 0 auto;
  }
`;

const NoProUserTitle = styled.h1`
  font-family: ${(props) => props.theme.styles.fontOutfit};
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
  color: ${(props) => props.theme.styles.colorBlack};
  @media (max-width: 575px) {
    font-size: 34px;
    margin-bottom: 2px;
  }
`;

const NoProUserText = styled.p`
  font-family: ${(props) => props.theme.styles.fontProximaNova};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 40px;
  color: ${(props) => props.theme.styles.colorBlack};
`;

const MonthYearToggleWrapper = styled.div`
  margin-top: 30px;
  text-align: center;
  .ant-switch {
    margin: 0 20px;
  }
`;

const ToggleText = styled.span`
  font-family: ${(props) => props.theme.styles.fontNunitoSans};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-align: right;
  color: ${(props) => props.theme.styles.colorBlack};
  &.reduce-opacity {
    opacity: 0.5;
  }
`;

export {
  NoProUserModalContainer,
  NoProUserTitle,
  NoProUserText,
  MonthYearToggleWrapper,
  ToggleText,
};
