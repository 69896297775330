import { ParagraphContainer } from './Typography.style';

const Paragraph = ({ className, children, ...restProps }) => {
  return (
    <ParagraphContainer
      className={`paragraph-container ${className}`}
      {...restProps}
    >
      {children}
    </ParagraphContainer>
  );
};

export default Paragraph;
