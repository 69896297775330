import { Switch } from 'antd';
import styled from 'styled-components';

const SwitchContainer = styled(Switch)`
  height: 28px;
  width: 45px;
  .ant-switch-handle {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 26px;
    height: 26px;
    transition: all 0.2s ease-in-out;
    &::before {
      box-shadow: none;
      border-radius: 50px;
    }
    & > span {
      top: 1px !important;
      padding: 5px;
    }
  }
  &.ant-switch-checked {
    background: ${(props) => props.theme.styles.colorBlue} !important;
    .ant-switch-handle {
      left: calc(100% - 25px - 2px);
    }
  }
`;

export default SwitchContainer;
