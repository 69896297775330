import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';

import Button from 'src/components/common/Button/Button';
import Switch from 'src/components/common/Switch/Switch';
import { CopyUrl } from 'src/components/icons';
import { addErrorMessage, addSuccessMessage } from 'src/state/ducks/ui/actions';
import { updateTeam } from 'src/api';

import {
  ShareTeamModalContainer,
  SecuritySectionWrapper,
  SecurityText,
  CopyUrlTitle,
  CopyUrlInput,
  CopyUrlInputGroup,
} from './ShareTeamModal.styles';

const ShareTeamModal = () => {
  const [updataLoading, setUpdataLoading] = useState(false);

  const history = useNavigate();
  const inputRef = useRef();
  const dispatch = useDispatch();

  const { loading, team } = useSelector(
    (store) => store.teamState.singleTeamReducers
  );

  const [isPrivate, setIsPrivate] = useState(team?.private);

  const location = window.location.href;
  const splitLocation = location.split('/share');

  const handleCancel = () => {
    history(-1);
  };

  const handleChange = async () => {
    try {
      setUpdataLoading(true);
      const token = localStorage.getItem('token');
      const { data } = await updateTeam(
        team?.url,
        { private: !isPrivate },
        token
      );
      const successMessage = data?.message;
      dispatch(addSuccessMessage(successMessage));
      setIsPrivate((prev) => !prev);
      setUpdataLoading(false);
    } catch (e) {
      const { data } = e.response;
      const errorMessage = data?.message;
      dispatch(addErrorMessage(errorMessage));
      setUpdataLoading(false);
    }
  };

  useEffect(() => {
    setIsPrivate(team?.private);
  }, [team?.private]);

  return (
    <ShareTeamModalContainer
      title="Share Team"
      isModalVisible
      handleCancel={handleCancel}
      footer={[
        <a target="_blank" href={splitLocation[0]} rel="noreferrer">
          Open in new window
        </a>,
      ]}
    >
      <SecuritySectionWrapper>
        <SecurityText>This team is private</SecurityText>
        <Switch
          checked={isPrivate}
          loading={loading || updataLoading}
          onChange={handleChange}
        />
      </SecuritySectionWrapper>
      <CopyUrlTitle>Copy this private url to share:</CopyUrlTitle>
      <CopyUrlInputGroup compact>
        <CopyUrlInput
          ref={inputRef}
          // style={{ width: 'calc(100% - 200px)' }}
          defaultValue={splitLocation[0]}
        />
        <Tooltip title="copy team url">
          <Button
            icon={<CopyUrl />}
            type="primary"
            onClick={() =>
              navigator.clipboard.writeText(inputRef.current.state.value)
            }
          >
            <span className="copy-text">Copy</span>
          </Button>
        </Tooltip>
      </CopyUrlInputGroup>
    </ShareTeamModalContainer>
  );
};

export default ShareTeamModal;
