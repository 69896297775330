import { Suspense, useEffect, useState, useCallback } from 'react';
import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { message } from 'antd';
import jwtDecode from 'jwt-decode';

import { logout, setCurrentUser } from 'src/state/ducks/user/actions';
import { addErrorMessage, addSuccessMessage } from 'src/state/ducks/ui/actions';
import { checkMe } from 'src/api';
import Fallback from 'src/components/Fallback';
import routes from 'src/routes';
import Intercom from 'src/components/common/Intercom/Intercom';

const App = () => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const newMessage = useSelector((store) => store.uiState.message);
  const { isAuthenticate, user } = useSelector(
    (store) => store.userState.currentUser
  );

  const checkAuth = useCallback(async () => {
    setLoading(true);
    try {
      const getToken = localStorage.getItem('token');
      if (getToken !== undefined) {
        const decoded = jwtDecode(getToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          setLoading(false);
          return dispatch(logout());
        }
        const { data } = await checkMe(getToken);
        const token = data?.token;
        dispatch(setCurrentUser(token));
        setLoading(false);
      }
    } catch (e) {
      dispatch(logout());
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    if (newMessage.successMessage) {
      message.success(newMessage.successMessage, 3, () => {
        dispatch(addSuccessMessage(null));
      });
    }
    if (newMessage.errorMessage) {
      message.error(newMessage.errorMessage, 3, () => {
        dispatch(addErrorMessage(null));
      });
    }
  }, [newMessage, dispatch]);
  if (loading) {
    return <Fallback />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Navigate
                to={isAuthenticate ? user?.siteName || 'guest' : '/login'}
              />
            }
          />
          {routes.map((route, i) => {
            const Compon = route.component;
            const Layout = route.layout;
            const { title } = route;

            return route.protected ? (
              <Route
                key={i}
                path={route.path}
                element={
                  isAuthenticate ? (
                    <Layout>
                      <Helmet>
                        {title && <title> Stacado - {title}</title>}
                      </Helmet>
                      <Compon />
                      {/* <Intercom /> */}
                    </Layout>
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
            ) : (
              <Route
                key={i}
                path={route.path}
                element={
                  <Layout>
                    <Helmet>
                      {title && <title> Stacado - {title}</title>}
                    </Helmet>
                    <Compon />
                    {/* <Intercom /> */}
                  </Layout>
                }
              />
            );
          })}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
