import React from 'react';
import ReactDOM from 'react-dom';

// import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import './index.css';
import 'antd/dist/antd.css';

import App from 'src/containers/App';
import configureStore from 'src/state/store';
import { SiteBoxThemeProvider } from 'src/theme/provider';

import reportWebVitals from './reportWebVitals';

function Container() {
  const reduxStore = configureStore(window.REDUX_INITIAL_DATA);
  return (
    <React.StrictMode>
      {/* <Router> */}
      <Provider store={reduxStore}>
        <SiteBoxThemeProvider>
          <App />
        </SiteBoxThemeProvider>
      </Provider>
      {/* </Router> */}
    </React.StrictMode>
  );
}

ReactDOM.render(<Container />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
