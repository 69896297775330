import styled from 'styled-components';
import { Form } from 'antd';

const BillingFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 410px;
  margin: 0 auto;
  padding-top: 80px;

  @media (max-width: 992px) {
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    width: 410px;
    margin: 0 auto;
    padding: 40px 20px 0 20px !important;
  }
  @media (max-width: 575px) {
    width: 100%;
    margin: 0 auto;
    padding: 40px 20px 0 20px !important;
  }

  .input-wrapper {
    margin-bottom: 16px;

    & > .ant-form-item-control {
      min-width: auto !important;
    }
  }

  .ant-form-item-label {
    display: block;
    width: 100%;
    text-align: inherit;
    font-family: ${(props) => props.theme.styles.fontProximaNova};
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: ${(props) => props.theme.styles.colorGrayLight};
    & label {
      &::after {
        content: '';
      }
    }
  }

  .title {
    margin-bottom: 20px;
    text-align: center;
    font-family: ${(props) => props.theme.styles.fontOutfit};
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 60px;
    color: #1c2227;
    span {
      font-weight: 700;
    }
  }
  .below-ints {
    margin-bottom: 20px;
    font-family: ${(props) => props.theme.styles.fontProximaNova};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1c2227;
    opacity: 0.7;
  }

  .btn-login {
    width: 100%;
    margin-top: 20px;
  }
`;

const SmallText = styled.p`
  font-family: ${(props) => props.theme.styles.fontNunitoSans};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 30px;

  color: ${(props) => props.theme.styles.colorBlack};
`;

const FormTitle = styled.h1`
  font-family: ${(props) => props.theme.styles.fontOutfit};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 50px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.styles.colorBlack};
`;

export { BillingFormContainer, SmallText, FormTitle };
