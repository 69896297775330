import { DeleteTextContainer } from './Typography.style';

const DeleteText = ({ className, children, ...restProps }) => {
  return (
    <DeleteTextContainer className={`text-container ${className}`} {...restProps}>
      {children}
    </DeleteTextContainer>
  );
};

export default DeleteText;
