import { useParams, useNavigate } from 'react-router-dom';
import Button from 'src/components/common/Button/Button';
import { Title } from 'src/components/common/Typography';
import {
  LinkIcon,
  CardIcon,
  FileIcon,
  ContactIcon,
  CodeIcon,
  VideoIcon,
  CloseModal,
} from 'src/components/icons';
import { ReactComponent as LogoWhite } from 'src/assets/icons/stacado_white.svg';
import ModalContainer from './AddCardModal.style';

const AddCardModal = () => {
  const history = useNavigate();
  const params = useParams();
  const teamUrl = params?.teamUrl;
  const projectUrl = params?.projectUrl;

  const handleCancel = () => {
    history(-1);
  };

  const handleClick = (type) => {
    history(`/create/card/${teamUrl}/${projectUrl}/${type}`);
  };

  return (
    <ModalContainer
      title={<LogoWhite />}
      isModalVisible
      handleCancel={handleCancel}
      footer={null}
      closeIcon={<CloseModal />}
    >
      <Title className="title" level={2}>
        Select a card
      </Title>
      <div className="button-group mb-40">
        <Button
          type="primary"
          className="btn-card btn-card-link mr-40"
          onClick={() => handleClick('link')}
        >
          <LinkIcon />
          <span className="title">Link</span>
          <span className="description">External URL</span>
        </Button>

        <Button
          type="primary"
          className="btn-card btn-card-card mr-40"
          onClick={() => handleClick('card')}
        >
          <CardIcon />
          <span className="title">Card</span>
          <span className="description">Plain Text</span>
        </Button>
        <Button
          type="primary"
          className="btn-card btn-card-file "
          onClick={() => handleClick('file')}
        >
          <FileIcon />
          <span className="title">File</span>
          <span className="description">Downloadable File</span>
        </Button>
        <Button
          type="primary"
          className="btn-card btn-card-contact mr-40"
          onClick={() => handleClick('contact')}
        >
          <ContactIcon />
          <span className="title">Contact</span>
          <span className="description">Contact info</span>
        </Button>
        <Button
          type="primary"
          className="btn-card btn-card-code mr-40"
          onClick={() => handleClick('code')}
        >
          <CodeIcon />
          <span className="title">Code</span>
          <span className="description">Code snippet</span>
        </Button>
        <Button
          type="primary"
          className="btn-card btn-card-video"
          // onClick={() => handleClick('contact')}
        >
          <VideoIcon />
          <span className="title">Video</span>
          <span className="description">Coming soon</span>
        </Button>
      </div>
    </ModalContainer>
  );
};

export default AddCardModal;
