import { combineReducers } from 'redux';

import types from './types';

const activity = (state = {}, action) => {
  switch (action.type) {
    case types.ADD_ACTIVITY:
      return action.payload;
    default:
      return state;
  }
};

const message = (state = {}, action) => {
  switch (action.type) {
    case types.SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: action.payload,
      };
    case types.ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

const uiReducer = combineReducers({
  activity,
  message,
});

export default uiReducer;
