import SwitchContainer from './Switch.styles';

const Switch = (props) => {
  const { onChange, checked, loading } = props;

  return (
    <SwitchContainer checked={checked} loading={loading} onChange={onChange}>
      switch
    </SwitchContainer>
  );
};

export default Switch;
