import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import { logout } from 'src/state/ducks/user/actions';

import Switch from 'src/components/common/Switch/Switch';
import { ReactComponent as LogoBlack } from 'src/assets/icons/stacado_white.svg';
import PlanCard from 'src/components/PlanCard/PlanCard';

import {
  NoProUserModalContainer,
  NoProUserTitle,
  NoProUserText,
  MonthYearToggleWrapper,
  ToggleText,
} from './NoProUserModal.styles';

const NoProUserModal = () => {
  const [checked, setChecked] = useState(false);

  const user = useSelector((store) => store.userState.currentUser.user);

  const history = useNavigate();
  const dispatch = useDispatch();

  const handleCancel = () => {
    if (user?.trialSeason === 0 && !user?.membership && user?.role !== 'guest') {
      dispatch(logout());
      history('/');
    } else {
      history(-1);
    }
  };

  const onChange = (value) => {
    setChecked(value);
  };

  if (user?.membership) {
    return <Navigate to="/" />;
  }

  return (
    <NoProUserModalContainer
      title={<LogoBlack />}
      isModalVisible
      handleCancel={handleCancel}
      footer={null}
    >
      <NoProUserTitle>Upgrade your account</NoProUserTitle>
      <NoProUserText>
        Give your account the features you need to succeed.
      </NoProUserText>
      <PlanCard
        title="Standard Plan"
        price={checked ? '49.00' : '4.99'}
        savePrice={10}
        billingCycle={checked ? 'yr' : 'mo'}
      />
      <MonthYearToggleWrapper>
        <ToggleText className={checked && 'reduce-opacity'}>MONTHLY</ToggleText>
        <Switch checked={checked} onChange={onChange} />
        <ToggleText className={!checked && 'reduce-opacity'}>YEARLY</ToggleText>
      </MonthYearToggleWrapper>
    </NoProUserModalContainer>
  );
};

export default NoProUserModal;
