const PublicIcon = () => {
  return (
    <svg
      width="48"
      height="24"
      viewBox="0 0 48 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6C0 2.68629 2.68629 0 6 0H42C45.3137 0 48 2.68629 48 6V18C48 21.3137 45.3137 24 42 24H6C2.68629 24 0 21.3137 0 18V6Z"
        fill="#2DCCD3"
      />
      <path
        d="M12.08 15V12.63H13.78C15.22 12.63 16.02 11.64 16.02 10.48C16.02 9.31 15.23 8.33 13.78 8.33H10.66V15H12.08ZM13.59 11.38H12.08V9.58H13.59C14.15 9.58 14.57 9.92 14.57 10.48C14.57 11.03 14.15 11.38 13.59 11.38ZM21.4002 15V10.17H20.1302V13.44C19.9102 13.72 19.5202 13.99 19.0302 13.99C18.4902 13.99 18.1402 13.77 18.1402 13.1V10.17H16.8702V13.6C16.8702 14.54 17.3702 15.12 18.4302 15.12C19.2202 15.12 19.8002 14.76 20.1302 14.39V15H21.4002ZM23.9313 13.45V11.74C24.1413 11.42 24.6113 11.18 25.0213 11.18C25.7613 11.18 26.2513 11.75 26.2513 12.59C26.2513 13.43 25.7613 13.99 25.0213 13.99C24.6113 13.99 24.1413 13.76 23.9313 13.45ZM23.9313 15V14.38C24.3113 14.86 24.8413 15.12 25.4213 15.12C26.6413 15.12 27.5613 14.2 27.5613 12.59C27.5613 11.01 26.6513 10.05 25.4213 10.05C24.8513 10.05 24.3113 10.3 23.9313 10.79V8.33H22.6613V15H23.9313ZM29.7906 15V8.33H28.5206V15H29.7906ZM31.6799 9.65C32.0999 9.65 32.4399 9.31 32.4399 8.89C32.4399 8.47 32.0999 8.14 31.6799 8.14C31.2699 8.14 30.9199 8.47 30.9199 8.89C30.9199 9.31 31.2699 9.65 31.6799 9.65ZM32.3199 15V10.17H31.0499V15H32.3199ZM35.8292 15.12C36.8192 15.12 37.4192 14.69 37.7392 14.24L36.9092 13.47C36.6792 13.79 36.3292 13.99 35.8892 13.99C35.1192 13.99 34.5792 13.42 34.5792 12.58C34.5792 11.74 35.1192 11.18 35.8892 11.18C36.3292 11.18 36.6792 11.36 36.9092 11.7L37.7392 10.92C37.4192 10.48 36.8192 10.05 35.8292 10.05C34.3392 10.05 33.2692 11.1 33.2692 12.58C33.2692 14.07 34.3392 15.12 35.8292 15.12Z"
        fill="white"
      />
    </svg>
  );
};

export default PublicIcon;
