import axios from "axios";

const endpoint = `${process.env.REACT_APP_API_ENDPOINT}/api/v1`;
// const endpoint = `http://localhost:3001/api/v1`;

const getHeaders = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
};

export const signUp = (data) => {
  return axios.post(`${endpoint}/auth/signup`, data);
};
export const guestSignUp = (data) => {
  return axios.put(`${endpoint}/auth/guestsignup`, data);
};

export const logIn = (data) => {
  return axios.post(`${endpoint}/auth/login`, data);
};
export const googlesignIn = (data) => {
  return axios.post(`${endpoint}/auth/googleAuth`, data);
};

export const googlesignUp = (data) => {
  return axios.post(`${endpoint}/auth/signupWithGoogle`, data);
};

export const addPaymentCard = (data, token) => {
  return axios.post(`${endpoint}/billing/addCard`, data, getHeaders(token));
};

export const getPaymentCard = (cardId, token) => {
  return axios.get(`${endpoint}/billing/card/${cardId}`, getHeaders(token));
};

export const deletePaymentCard = (cardId, token) => {
  return axios.delete(`${endpoint}/billing/card/${cardId}`, getHeaders(token));
};

export const UpdatePaymentCard = (data, token) => {
  return axios.put(
    `${endpoint}/billing/updateDefaultSource`,
    data,
    getHeaders(token)
  );
};

export const billing = (data, token) => {
  return axios.post(`${endpoint}/billing`, data, getHeaders(token));
};

export const checkMe = (token) => {
  return axios.post(`${endpoint}/auth/me`, {}, getHeaders(token));
};
export const deleteAccount = (token) => {
  return axios.delete(`${endpoint}/auth/deleteAccount`, getHeaders(token));
};

export const markTrialAsEnded = (token) => {
  return axios.put(`${endpoint}/auth/markTrialAsEnded`, {}, getHeaders(token));
};

export const resendVerificationEmail = (token) => {
  return axios.post(`${endpoint}/auth/sendEmailAgain`, {}, getHeaders(token));
};

export const activeEmail = (data) => {
  return axios.put(`${endpoint}/auth/activeEmail`, data);
};

export const forgetPassword = (data) => {
  return axios.post(`${endpoint}/auth/forgetPassword`, data);
};

export const matchOtp = (data) => {
  return axios.post(`${endpoint}/auth/matchOtp`, data);
};

export const resetPassword = (data, token) => {
  return axios.put(`${endpoint}/auth/resetPassword`, data, getHeaders(token));
};

export const updateUser = (data, token) => {
  return axios.put(`${endpoint}/auth/updateUser`, data, getHeaders(token));
};

export const updateUserImage = (data, token) => {
  return axios.put(`${endpoint}/auth/updateUserImage`, data, getHeaders(token));
};

export const updateBilling = (data, token) => {
  return axios.put(
    `${endpoint}/billing/updateBilling`,
    data,
    getHeaders(token)
  );
};

export const getInvoices = (token) => {
  return axios.get(`${endpoint}/billing/userInvoices`, getHeaders(token));
};

export const retrieveSubscription = (token) => {
  return axios.get(`${endpoint}/billing/subscription`, getHeaders(token));
};

export const cancelSubscription = (token, data) => {
  return axios.put(`${endpoint}/billing/subscription`, data, getHeaders(token));
};

export const reactivateSubscription = (token, data) => {
  return axios.put(
    `${endpoint}/billing/reactive-subscription`,
    data,
    getHeaders(token)
  );
};

export const getCards = (token) => {
  return axios.get(`${endpoint}/billing/cards`, getHeaders(token));
};

export const getUsers = (name, token) => {
  return axios.get(`${endpoint}/auth/users/${name}`, getHeaders(token));
};

export const createTeam = (data, token) => {
  return axios.post(`${endpoint}/team/create`, data, getHeaders(token));
};

export const existUser = (email) => {
  return axios.get(`${endpoint}/auth/existUser/${email}`);
};

export const getTeams = (searchString = "", token) => {
  return axios.get(
    `${endpoint}/team/allTeams/${searchString}`,
    getHeaders(token)
  );
};

export const getSiteName = (searchString = "", token) => {
  return axios.get(
    `${endpoint}/auth/siteName/${searchString}`,
    getHeaders(token)
  );
};

export const getTeamUrl = (searchString = "", token) => {
  return axios.get(
    `${endpoint}/team/teamUrl/${searchString}`,
    getHeaders(token)
  );
};

export const myTeams = (searchString = "", token) => {
  return axios.get(
    `${endpoint}/team/ownTeams/${searchString}`,
    getHeaders(token)
  );
};

export const sharedTeams = (searchString = "", token) => {
  return axios.get(
    `${endpoint}/team/sharedTeams/${searchString}`,
    getHeaders(token)
  );
};

export const getTeam = (siteName, teamUrl, token) => {
  return axios.get(
    `${endpoint}/team/${siteName}/${teamUrl}`,
    getHeaders(token)
  );
};

export const editTeam = (teamId, data, token) => {
  return axios.put(`${endpoint}/team/${teamId}`, data, getHeaders(token));
};

export const updateTeam = (teamId, data, token) => {
  return axios.put(`${endpoint}/team/${teamId}`, data, getHeaders(token));
};

export const deleteTeam = (teamId, token) => {
  return axios.delete(`${endpoint}/team/${teamId}`, getHeaders(token));
};

export const deleteAllUserTeams = () => {
  const token = localStorage.getItem("token");
  return axios.delete(`${endpoint}/team/deleteAllUserTeams`, getHeaders(token));
};

export const restoreAllUserTeams = () => {
  const token = localStorage.getItem("token");
  return axios.put(
    `${endpoint}/team/restoreAllUserTeams`,
    {},
    getHeaders(token)
  );
};

export const createProject = (data, token) => {
  return axios.post(`${endpoint}/project/create`, data, getHeaders(token));
};

export const getProjects = (teamUrl, siteName, searchString = "", token) => {
  return axios.get(
    `${endpoint}/project/allprojects/${siteName}/${teamUrl}/${searchString}`,
    getHeaders(token)
  );
};

export const getProject = (siteName, teamUrl, projectSlug, token) => {
  return axios.get(
    `${endpoint}/project/${siteName}/${teamUrl}/${projectSlug}`,
    getHeaders(token)
  );
};

export const updateProject = (projectId, data, token) => {
  return axios.put(`${endpoint}/project/${projectId}`, data, getHeaders(token));
};

export const deleteProject = (projectId, token) => {
  return axios.delete(`${endpoint}/project/${projectId}`, getHeaders(token));
};

export const createCard = (projectId, data, token) => {
  return axios.post(`${endpoint}/card/${projectId}`, data, getHeaders(token));
};

export const getAllCards = (teamUrl, siteName, projectSlug, token) => {
  console.log("Get all cards called :", teamUrl, siteName, projectSlug);
  return axios.get(
    `${endpoint}/card/getAllCards/${teamUrl}/${siteName}/${projectSlug}`,
    getHeaders(token)
  );
};

export const updateCardOrders = (cards) => {
  const token = localStorage.getItem("token");
  return axios.post(
    `${endpoint}/card/update-card-orders`,
    cards,
    getHeaders(token)
  );
};

export const deleteCard = (projectId, cardId, token) => {
  return axios.delete(
    `${endpoint}/card/${projectId}/${cardId}`,
    getHeaders(token)
  );
};

export const getCard = (projectId, cardSlug, token) => {
  console.log("getcard called :", projectId, cardSlug);
  return axios.get(
    `${endpoint}/card/${projectId}/${cardSlug}`,
    getHeaders(token)
  );
};

export const updateCard = (projectId, cardId, data, token) => {
  console.log("Update card called:: :::");
  return axios.put(
    `${endpoint}/card/${projectId}/${cardId}`,
    data,
    getHeaders(token)
  );
};