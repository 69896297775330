import styled from 'styled-components';
import { Layout } from 'antd';

const { Footer } = Layout;
const FooterWrapper = styled.div`
  .footer-space {
    display: none;
    @media (max-width: 575px) {
      height: 34px;
      display: block;
    }
  }
`;

const FooterContainer = styled(Footer)`
  flex-shrink: 0;
  height: 70px;
  background: rgba(0, 0, 0, 0.0001);
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  @media (max-width: 575px) {
    flex-direction: column;
    height: auto;
    padding: 24px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  .rights {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 575px) {
        margin-bottom: 24px;
    }
    > span {
      margin-left: 40px;
        @media (max-width: 575px) {
            margin-left: 24px;
        }
    }
  }
  .terms {
    display: flex;
    align-items: center;
    @media (max-width: 575px) {
        flex-direction: column;
        height: auto;
    }
    .row_1 {
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
        @media (max-width: 575px) {
            margin-bottom: 24px;
        }
      > div {
        padding: 0 15px;
        text-align: center;
      }
    }
    .social {
      display: flex;
      align-items: center;
      list-style: none;
      margin-bottom: 0;
      padding: 0 0 0 40px;
      > li {
        display: flex;
        align-items: center;
        margin: 0 6px;

        &:first-child,
        &:last-child {
          margin-top: 2px;
        }
      }
    }
  }
`;

export { FooterContainer, FooterWrapper };
