import styled from 'styled-components';
import { Card } from 'antd';

const PlanCardContainer = styled(Card)`
  position: relative;
  width: 240px;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  background: ${(props) => props.theme.styles.colorWhite};
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  & .ant-card-head {
    border: 0;
  }

  & .ant-card-head-title {
    font-family: ${(props) => props.theme.styles.fontOutfit};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: ${(props) => props.theme.styles.colorBlack};
    padding: 29px 0 20px;
  }

  .ant-card-body {
    padding: 0 20px 24px;
    & .ant-btn {
      width: 100%;
      margin-top: 20px;
    }
  }
`;

const PlanPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PriceWrapper = styled.h2`
  position: relative;
`;

const DollarSignWrapper = styled.span`
  position: absolute;
  content: '';
  top: 19px;
  left: -7px;
  font-family: ${(props) => props.theme.styles.fontProximaNova};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => props.theme.styles.colorBlack};
`;

const IntegerWrapper = styled.span`
  font-family: ${(props) => props.theme.styles.fontProximaNova};
  font-weight: 400;
  font-size: 50px;
  color: ${(props) => props.theme.styles.colorBlack};
`;

const DecimalWrapper = styled.span`
  position: absolute;
  content: '';
  top: 13px;
  font-family: ${(props) => props.theme.styles.fontProximaNova};
  font-weight: 400;
  font-size: 18px;
  color: ${(props) => props.theme.styles.colorBlack};
`;

const MonthYearTextWrapper = styled.small`
  font-family: ${(props) => props.theme.styles.fontProximaNova};
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  margin: 8px 0 0 20px;
`;

const OfferText = styled.p`
  font-family: ${(props) => props.theme.styles.fontProximaNova};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #fa635d;
  text-align: center;
  margin-bottom: 14px;
`;

const PlanFacilityWrapper = styled.div`
  & svg {
    margin-right: 7px;
    & path {
      fill: ${(props) => props.theme.styles.colorBlack} !important;
      opacity: 0.6;
    }
  }
`;

const FacilityText = styled.span`
  font-family: ${(props) => props.theme.styles.fontProximaNova};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.4px;
  color: ${(props) => props.theme.styles.colorBlack};
  opacity: 0.5;
`;

const Border = styled.span`
  position: absolute;
  content: '';
  top: -1px;
  left: 0;
  right: 0;
  height: 6px;
  background: #596476;
`;

export {
  PlanCardContainer,
  PlanPriceWrapper,
  PriceWrapper,
  DollarSignWrapper,
  IntegerWrapper,
  DecimalWrapper,
  MonthYearTextWrapper,
  OfferText,
  PlanFacilityWrapper,
  FacilityText,
  Border,
};
