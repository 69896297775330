import { useNavigate } from 'react-router-dom';

import { CheckSolid } from 'src/components/icons';
import Button from 'src/components/common/Button/Button';

import {
  PlanCardContainer,
  PlanPriceWrapper,
  PriceWrapper,
  DollarSignWrapper,
  IntegerWrapper,
  DecimalWrapper,
  MonthYearTextWrapper,
  OfferText,
  PlanFacilityWrapper,
  FacilityText,
  Border,
} from './PlanCard.styles';

const planCardData = ['Unlimited teams', 'Unlimited stacks'];

const PlanCard = (props) => {
  const { title, price, savePrice, billingCycle } = props;

  const splitPrice = price && price.split('.');

  const history = useNavigate();

  return (
    <PlanCardContainer title={title}>
      <PlanPriceWrapper>
        <PriceWrapper>
          <DollarSignWrapper>$</DollarSignWrapper>
          <IntegerWrapper>{splitPrice[0] ? splitPrice[0] : 0}</IntegerWrapper>
          <DecimalWrapper>{splitPrice[1] && splitPrice[1]}</DecimalWrapper>
        </PriceWrapper>
        <MonthYearTextWrapper>/ {billingCycle}</MonthYearTextWrapper>
      </PlanPriceWrapper>
      <OfferText>Save ${savePrice} a year</OfferText>
      {Array.isArray(planCardData) &&
        planCardData.map((item, i) => (
          <PlanFacilityWrapper key={i}>
            <CheckSolid />
            <FacilityText>{item}</FacilityText>
          </PlanFacilityWrapper>
        ))}
      <Button
        type="primary"
        onClick={() =>
          history('/billing', {
            state: {
              billingCycle,
            },
          })
        }
      >
        Upgrade now
      </Button>
      <Border />
    </PlanCardContainer>
  );
};

export default PlanCard;
