import styled from 'styled-components';
import { Layout, Alert } from 'antd';

const { Content } = Layout;

const ProtectedContent = styled(Content)`
  background: #ffffff;
  flex: 1 0 auto;
  min-height: calc(100vh - 140px);
`;
const AlertContent = styled(Alert)`
  background: #ff0064;
  border: none;
  box-shadow: 0px 1px 0px #dedede, inset 0px -1px 0px rgba(0, 0, 0, 0.3);
  .ant-alert-content {
    .ant-alert-message {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          margin-left: 7px;
          font-family: ${(props) => props.theme.styles.fontProximaNova};
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 14px;
          color: #ffffff;
        }
      }
    }
  }
`;

export { ProtectedContent, AlertContent };
