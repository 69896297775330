const CodePlus = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 12H15"
        stroke="#901D93"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9V15"
        stroke="#901D93"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 19C4.89543 19 4 18.1046 4 17V13L3 12L4 11V7C4 5.89543 4.89543 5 6 5"
        stroke="#901D93"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 19C19.1046 19 20 18.1046 20 17V13L21 12L20 11V7C20 5.89543 19.1046 5 18 5"
        stroke="#901D93"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CodePlus;
