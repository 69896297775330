import { TitleContainer } from './Typography.style';

const Title = ({ className, children, ...restProps }) => {
  return (
    <TitleContainer className={`title-container ${className}`} {...restProps}>
      {children}
    </TitleContainer>
  );
};

export default Title;
