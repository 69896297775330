import { combineReducers } from 'redux';

import types from './types';

const initialState = { invitedMembers: [] };

const invitedMemberReducers = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_INVITED_MEMBERS:
      return {
        ...state,
        invitedMembers: action.payload,
      };
    case types.REMOVE_INVITED_MEMBER:
      return {
        ...state,
        invitedMembers: [...action.payload],
      };
    default:
      return state;
  }
};

const singleTeamReducers = (state = { loading: true }, action) => {
  switch (action.type) {
    case types.ADD_SINGLE_TEAM_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const teamReducers = combineReducers({
  invitedMemberReducers,
  singleTeamReducers,
});

export default teamReducers;
