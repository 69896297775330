import styled from 'styled-components';
import { Tabs, Select, Input } from 'antd';

const { Search } = Input;

const { TabPane } = Tabs;

/**
 * @description The following style is for the messsage in the middle of the Protected Header.
 */
export const HeaderMessage = styled.p`
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  gap: 3px;
  align-items: center;
  display: flex;
  color: #ffffff;
  a {
    font-weight: 600;
    text-decoration: none !important;
  }
  @media (max-width: 900px) {
    display: block;
    position: fixed;
    bottom: 20px;
    left: 50%;
    width: 98%;
    transform: translateX(-50%);
    background: ${(props) => props.theme.styles.colorDarkBlue};
    padding: 15px 85px 15px 20px;
    line-height: 1.5;
    border-radius: 16px;

    a {
      display: inline;
    }
  }
`;

const SearchContainer = styled(Search)`
  width: 300px !important;
  position: absolute;
  z-index: 100;
  top: 44px;
  &.opacity-0 {
    opacity: 0;
  }
  &.opacity-1 {
    opacity: 1;
  }
  .ant-input-affix-wrapper {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: ${(props) => props.theme.styles.colorWhite};
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    padding: 8px 10px;
    border: 1px solid #c9c9c9;
    &:hover {
      border-color: ${(props) => props.theme.styles.colorLightBlue};
    }
    &:focus,
    &.ant-input-affix-wrapper-focused {
      box-shadow: 0 0 0 1px rgb(255 0 100 / 20%);
      border-color: ${(props) => props.theme.styles.colorLightBlue};
    }
  }
  .ant-input-search-button {
    height: 40px;
    border: 1px solid #c9c9c9;
    &:hover {
      border-color: ${(props) => props.theme.styles.colorLightBlue};
    }
    &:focus,
    &.ant-input-affix-wrapper-focused {
      box-shadow: 0 0 0 1px rgb(255 0 100 / 20%);
      border-color: ${(props) => props.theme.styles.colorLightBlue};
    }
  }
`;

const NavContainer = styled.div``;

const NavTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0 0 23px 0;
    height: 50px;
    &:before {
      border-bottom: 0;
    }
    .ant-tabs-ink-bar {
      height: 2px !important;
      background: ${(props) => props.theme.styles.colorLightBlue};
      margin-bottom: 5px;
    }
    .ant-tabs-extra-content {
      position: relative;
      /* .tabs-search {
        cursor: pointer;
        width: 26px;
        height: 26px;
        display: block;
        margin: 0 30px 0 3px;
        svg {
          width: 100%;
          height: 100%;
        }
      } */
      .view-select {
        margin: 0 5px;
        display: flex;
        align-items: center;
        box-shadow: 0 3px 8px -1px rgba(50, 50, 71, 0.05);
        filter: drop-shadow(0 0 1px rgba(12, 26, 75, 0.1));
        border-radius: 4px;
        background: #fff;
        overflow: hidden;
        .view-padding {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 10px;
        }
        .bg-view {
          background: #edf2f7;
        }
      }
    }
    .ant-tabs-nav-wrap {
      .ant-tabs-tab {
        font-family: ${(props) => props.theme.styles.fontProximaNova};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        &:hover {
          color: ${(props) => props.theme.styles.colorLightBlue};
        }
        .ant-tabs-tab-btn {
          &:active {
            color: ${(props) => props.theme.styles.colorLightBlue};
          }
        }
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: ${(props) => props.theme.styles.colorBlack};
        }
      }
    }
    .ant-tabs-extra-content {
      .tabs-short {
        margin: 0;
      }
    }
    @media (max-width: 575px) {
      display: none;
    }
  }

  @media (max-width: 1110px) {
    margin: 0px 20px;
  }
  @media (max-width: 575px) {
    margin-top: 30px;
  }
`;

const NavSelect = styled(Select)`
  width: 170px;
  color: ${(props) => props.theme.styles.colorBlack} !important;
  font-family: ${(props) => props.theme.styles.fontProximaNova};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  .ant-select-selector {
    box-shadow: none !important;
    border: 0 !important;
    border-right-width: 0 !important;
    border-radius: 0 !important;
    .ant-select-selection-item {
      text-align: right;
    }
  }
  .ant-select-arrow .anticon > svg {
    color: #000 !important;
  }
`;
const NavTabsPane = styled(TabPane)`
  margin-top: 100px;
`;
const NavWrapper = styled.div`
  z-index: 1;

  .select {
    margin: 35px 20px 0 20px;
    display: none;
    @media (max-width: 575px) {
      display: block;
      margin-top: 5px;
    }
    > div {
      box-shadow: 0px 0px 1px rgb(12 26 75 / 24%),
        0px 3px 8px -1px rgb(50 50 71 / 5%);
      border-radius: 4px;
      border-color: transparent;
    }
    svg {
      color: #27272e;
    }
  }
`;

export {
  SearchContainer,
  NavContainer,
  NavTabs,
  NavTabsPane,
  NavSelect,
  NavWrapper,
};
