import {
  CheckboxContainer,
  InputContainer,
  InputPasswordContainer,
  InputTextareaContainer,
} from './Input.styles';

export const Input = (props) => {
  const { className, ...restProps } = props;

  return <InputContainer className={className} {...restProps} />;
};

export const Checkbox = (props) => {
  const { className, ...restProps } = props;
  return <CheckboxContainer className={className} {...restProps} />;
};

export const InputPassword = ({ className, status, message, ...restProps }) => {
  return <InputPasswordContainer className={`${className}`} {...restProps} />;
};

export const InputTextarea = (props) => {
  const { className, ...restProps } = props;

  return <InputTextareaContainer className={className} {...restProps} />;
};
