import { lazy } from 'react';

// modals
import ShareTeamModal from 'src/pages/ShareTeamModal/ShareTeamModal';
import NoProUserModal from 'src/pages/NoProUserModal/NoProUserModal';
import AddCardModal from 'src/pages/AddCardModal/AddCardModal';

// layouts
import PublicLayout from 'src/containers/Layout/PublicLayout/PublicLayout';
import ProtectedLayout from 'src/containers/Layout/ProtectedLayout/ProtectedLayout';
import EmptyLayout from 'src/containers/Layout/EmptyLayout/EmptyLayout';

// pages
const AuthGuest = lazy(() => import('src/pages/AuthGuest/AuthGuest'));
const Login = lazy(() => import('src/pages/Login/Login'));
const Signup = lazy(() => import('src/pages/Signup/Signup'));
// const Auth = lazy(() => import('src/pages/Auth/Auth'));
const OtpValidate = lazy(() => import('src/pages/OtpValidate/OtpValidate'));
const AddSiteUrl = lazy(() => import('src/pages/AddSiteUrl/AddSiteUrl'));
const Billing = lazy(() => import('src/pages/Billing/Billing'));
const PasswordRecovery = lazy(() =>
  import('src/pages/PasswordRecovery/PasswordRecovery')
);
const RecoveryOtpValidation = lazy(() =>
  import('src/components/RecoveryOtpValidation/RecoveryOtpValidation')
);
const Teams = lazy(() => import('src/pages/Teams/Teams'));
const ProjectList = lazy(() => import('src/pages/ProjectList/ProjectList'));
const ProjectDetail = lazy(() =>
  import('src/pages/ProjectDetail/ProjectDetail')
);
const Verify = lazy(() => import('src/pages/Verify/Verify'));
const ResetPasswordPage = lazy(() =>
  import('src/pages/ResetPasswordPage/ResetPasswordPage')
);

const AccountSettings = lazy(() =>
  import('src/pages/AccountSettings/AccountSettings')
);
const BillingDetailsPage = lazy(() =>
  import('src/pages/BillingDetailsPage/BillingDetailsPage')
);
const CreateTeamPage = lazy(() => import('src/pages/CreateTeam/CreateTeam'));
const CreateProjectCard = lazy(() =>
  import('src/pages/CreateProjectCard/CreateProjectCard')
);
const EditCard = lazy(() => import('src/pages/EditCard/EditCard'));
const CreateProjectPage = lazy(() =>
  import('src/pages/CreateProject/CreateProject')
);
const EditProjectPage = lazy(() => import('src/pages/EditProject/EditProject'));
const EditTeamPage = lazy(() => import('src/pages/EditTeam/EditTeam'));
const EditPaymentMethod = lazy(() =>
  import('src/pages/EditPaymentMethod/EditPaymentMethod')
);

export default [
  {
    path: '/:siteName',
    exact: false,
    protected: true,
    component: Teams,
    layout: ProtectedLayout,
    child: null,
    title: 'Teams',
  },
  {
    path: '/:siteName/:teamUrl',
    exact: true,
    protected: true,
    component: ProjectList,
    layout: ProtectedLayout,
    child: null,
    title: 'Team',
  },
  {
    path: '/create/team',
    protected: true,
    component: CreateTeamPage,
    layout: ProtectedLayout,
    child: null,
    title: 'Create a Team',
  },
  {
    path: '/create/card/:teamUrl/:projectUrl/:type',
    protected: true,
    component: CreateProjectCard,
    layout: ProtectedLayout,
    child: null,
    title: 'Create a Card',
  },
  {
    path: '/edit/card/:teamUrl/:projectUrl/:type/:cardSlug',
    protected: true,
    component: EditCard,
    layout: ProtectedLayout,
    child: null,
    title: 'Create a Card',
  },
  {
    path: '/edit/team/:teamUrl',
    protected: true,
    component: EditTeamPage,
    layout: ProtectedLayout,
    child: null,
    title: 'Update Team',
  },
  {
    path: '/:siteName/:teamUrl/create/project',
    // exact: true,
    protected: true,
    component: CreateProjectPage,
    layout: ProtectedLayout,
    child: null,
    title: 'Create a Stack',
  },
  {
    path: '/edit/project/:siteName/:teamUrl/:projectSlug',
    protected: true,
    component: EditProjectPage,
    layout: ProtectedLayout,
    child: null,
    title: 'Update Stack',
  },
  {
    path: '/:siteName/:teamUrl/:projectUrl',
    protected: false,
    component: ProjectDetail,
    layout: ProtectedLayout,
    child: null,
    title: 'Stack',
  },
  {
    path: '/billing',
    protected: true,
    component: Billing,
    layout: ProtectedLayout,
    child: null,
    title: 'Billing',
  },
  {
    path: '/account/billing-details',
    protected: true,
    component: BillingDetailsPage,
    layout: ProtectedLayout,
    child: null,
    title: 'Billing Details',
  },
  {
    path: '/account/settings',
    protected: true,
    component: AccountSettings,
    layout: ProtectedLayout,
    child: null,
    title: 'Account Settings',
  },
  {
    path: '/auth/guest',
    protected: false,
    component: AuthGuest,
    layout: PublicLayout,
    child: null,
    title: 'Guest',
  },
  {
    path: '/login',
    protected: false,
    component: Login,
    layout: PublicLayout,
    child: null,
    title: 'Sign In',
  },
  {
    path: '/signup',
    protected: false,
    component: Signup,
    layout: PublicLayout,
    child: null,
    title: 'Signup',
  },
  {
    path: '/otp-validate',
    protected: false,
    component: OtpValidate,
    layout: PublicLayout,
    child: null,
    title: 'Validate',
  },
  {
    path: '/add-site-url',
    protected: false,
    component: AddSiteUrl,
    layout: PublicLayout,
    child: null,
    title: 'Add your organization URL',
  },
  {
    path: '/recovery',
    protected: false,
    component: PasswordRecovery,
    layout: PublicLayout,
    child: null,
    title: 'Password Recovery',
  },
  {
    path: '/recovery/verify',
    protected: false,
    component: RecoveryOtpValidation,
    layout: PublicLayout,
    child: null,
    title: 'Password Recovery Validation',
  },
  {
    path: '/verify',
    protected: false,
    component: Verify,
    layout: EmptyLayout,
    child: null,
    title: 'Verify',
  },
  {
    path: '/resetPassword',
    protected: false,
    component: ResetPasswordPage,
    layout: PublicLayout,
    child: null,
    title: 'Reset Password',
  },
  {
    path: '/team/:teamUrl/share',
    protected: true,
    component: ShareTeamModal,
    layout: EmptyLayout,
    child: null,
    title: 'Share Team',
  },
  {
    path: '/upgrade-plan',
    protected: true,
    component: NoProUserModal,
    layout: EmptyLayout,
    child: null,
    title: 'Upgrade Plan',
  },
  {
    path: '/add/card-type/:teamUrl/:projectUrl',
    protected: true,
    component: AddCardModal,
    layout: EmptyLayout,
    child: null,
    title: 'Add a Card',
  },
  {
    path: '/payment-update/:cardId',
    protected: true,
    component: EditPaymentMethod,
    layout: ProtectedLayout,
    child: null,
    title: 'Update Payment Method',
  },
  {
    path: '/add/payment-card',
    protected: true,
    component: EditPaymentMethod,
    layout: ProtectedLayout,
    child: null,
    title: 'Add Payment Method',
  },

];
