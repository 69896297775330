import BasicSelect from 'react-select';

export default function Select(props) {
  const customTheme = (theme) => ({
    ...theme,
    borderRadius: 2,
    colors: {
      ...theme.colors,
      primary25: '#d9d9d9',
      primary: '#d9d9d9',
    },
  });

  const customStyle = {
    control: (contorlStyles) => ({
      ...contorlStyles,
      height: '50px',
      ':hover': { borderColor: '#c9c9c9', color: '#fff' },
    }),
    indicatorSeparator: (isStyles) => ({ ...isStyles, display: 'none' }),
  };

  return <BasicSelect theme={customTheme} styles={customStyle} {...props} />;
}
