import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'src/components/common/Typography';
// import { BellIcon } from 'src/components/icons';
import { generateUrl, getFirstTwoChar } from 'src/utils';
import LogoWhite from '../icons/LogoWhite';

import Button from '../common/Button/Button';
import CardMenu from './CardMenu';

import {
  HeaderContainer,
  DropdownContainer,
  CustromAvatar,
  CustromBadge,
} from './ProtectedHeader.style';
import { HeaderMessage } from '../NavHeader/NavHeader.style';
import Timer from './Timer';

const ProtectedHeader = () => {
  const [visible, setVisible] = useState(false);

  const { user, isAuthenticate } = useSelector(
    (store) => store.userState.currentUser
  );

  const onVisibleChange = (e) => {
    setVisible(e);
  };

  const userImage = generateUrl(user?.image);
  const userEmail = user?.email;
  const getEmailChar = useMemo(() => getFirstTwoChar(userEmail), [userEmail]);

  return (
    <HeaderContainer
      className="header-container-black"
      style={{
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div className="header-logo">
        <Link to="/">
          <LogoWhite />
        </Link>
        {isAuthenticate ? (
          <Link to="/">
            <span className="team-link">Teams</span>
          </Link>
        ) : (
          ''
        )}
      </div>
      {(user?.paymentIntentStatus === 'requires_payment_method' ||
        user?.paymentIntentStatus === 'requires_action') &&
        user?.subscriptionStatus !== 'canceled' && (
          <HeaderMessage>
            There is a problem with your payment method. Please update your
            payment method
            <Link to="/account/billing-details"> Go to settings</Link>
          </HeaderMessage>
        )}
      {user?.siteName === 'guest' && user?.role !== 'guest' && (
        <HeaderMessage>
          Please setup your profile url to use Stacado.
          <Link to="/account/settings"> Go to settings</Link>
        </HeaderMessage>
      )}
      <div>
        {isAuthenticate ? (
          <div className="header-right">
            {user?.membership === false && user?.role !== 'guest' && (
              <div
                style={{
                  color: 'white',
                  marginRight: '10px',
                  fontWeight: '700',
                }}
                className="free-trial-status"
              >
                {user?.trialSeason === 0 ? (
                  'Upgrade Your Plan'
                ) : (
                  <Timer user={user} />
                )}
              </div>
            )}
            {/* <BellIcon /> */}
            <DropdownContainer
              placement="bottomRight"
              overlay={<CardMenu setVisible={setVisible} />}
              trigger={['click']}
              visible={visible}
              onVisibleChange={onVisibleChange}
            >
              {userImage ? (
                <CustromBadge>
                  <CustromAvatar src={userImage} />
                </CustromBadge>
              ) : (
                <CustromBadge>
                  <CustromAvatar>{getEmailChar}</CustromAvatar>
                </CustromBadge>
              )}
            </DropdownContainer>
          </div>
        ) : (
          <div className="header-right">
            <Link to="/signup">
              <Button className="btn-header" type="primary">
                Try For Free
              </Button>
            </Link>
          </div>
        )}
      </div>
    </HeaderContainer>
  );
};

export default ProtectedHeader;
