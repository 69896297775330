import React from 'react';
import { ThemeProvider } from 'styled-components';
import styles from './index';

const ThemeToggleContext = React.createContext();

export const useTheme = () => React.useContext(ThemeToggleContext);

export const SiteBoxThemeProvider = ({ children }) => {
  const [themeState, setThemeState] = React.useState({
    mode: localStorage.getItem('siteboxtheme') || 'primary',
  });

  const storeConfig = async (mode) => {
    localStorage.setItem('siteboxtheme', mode);
  };

  const toggle = () => {
    const mode = themeState.mode === 'primary' ? `primary` : `secondary`;
    setThemeState({ mode });
    storeConfig(themeState.mode === 'primary' ? `primary` : `secondary`);
  };

  return (
    <ThemeToggleContext.Provider value={{ toggle }}>
      <ThemeProvider
        theme={{
          mode: themeState.mode,
          styles,
        }}
      >
        {children}
      </ThemeProvider>
    </ThemeToggleContext.Provider>
  );
};

export default SiteBoxThemeProvider;
